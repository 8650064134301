import React, { useEffect, useState } from 'react';
import { Paper } from '@material-ui/core';
import ReactMarkdown from 'react-markdown';
import './Changelog.module.css';

export const Changelog = () => {
  const [data, setData] = useState('');

  useEffect(() => {
    fetch('/changelog.md')
    .then((result) => result.text())
    .then((text) => {
      setData(text);
    });
  }, []);

  return (
    <div className="changelog-container">
      <Paper>
        { data && <ReactMarkdown source={data} className="changelog"/> }
      </Paper>
    </div>
  );
};
