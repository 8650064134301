import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Alerts } from './Alerts/Alerts';
import { FaultsPagedTable } from '../../Shared/FaultsPagedTable/FaultsPagedTable';
import { BusContext } from '../../Contexts/BusContext';

export const AlertsAndIssues = () => {
  const { faults } = useContext(BusContext);
  const { tab = 'alerts' } = useParams();
  const history = useHistory();
  const [tabValue, setTabValue] = useState(tab);

  // When the user changes tab, update the history/URL.
  // The `useEffect` below will then change the component's state.
  const changeTab = (e, newValue) => {
    history.push(`/faults-and-alerts/${newValue}`);
  };

  // This will watch the `tab` path param and change state accordingly. Without
  // this, the back/forward browser buttons won't trigger a state change and
  // won't change the tab.
  useEffect(() => {
    setTabValue(tab);
  }, [tab]);

  return (
    <>
      <AppBar position='static' className='tab-bar'>
        <Tabs value={tabValue} onChange={changeTab}>
          <Tab label='MY ALERTS' className='tab' value='alerts' />
          <Tab label='ALL FAULTS' className='tab' value='issues' />
        </Tabs>
      </AppBar>
      {tabValue === 'alerts' && <Alerts />}
      {tabValue === 'issues' && <FaultsPagedTable faults={faults} />}
    </>
  );
};
