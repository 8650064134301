import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useFeatureFlags } from 'components/Contexts/FeatureFlagContext';
import { UpdatesAvailable } from './UpdatesAvailable/UpdatesAvailable.jsx';
import { TrimManagement } from './TrimManagement/TrimManagement.jsx';
import './Updates.scss';

export const Updates = () => {
  const { tab = 'updates' } = useParams();
  const [tabValue, setTabValue] = useState(tab);
  const history = useHistory();
  const { trimManagement } = useFeatureFlags();

  // When the user changes tab, update the history/URL.
  // The `useEffect` below will then change the component's state.
  const changeTab = ( e, newValue ) => {
    history.push(`/software-updates/${newValue}`);
  };

  // This will watch the `tab` path param and change state accordingly. Without
  // this, the back/forward browser buttons won't trigger a state change and
  // won't change the tab.
  useEffect(() => {
    setTabValue(tab);
  }, [tab]);

  return (
    <>
      <AppBar position='static' className='tab-bar'>
        {/* a bit of a hack to avoid console warnings on early renders */}
        { trimManagement !== undefined &&
          <Tabs value={tabValue} onChange={changeTab}>
            <Tab label='AVAILABLE SOFTWARE UPDATES' className='tab' value="updates" key="updatesTab"/>
            {trimManagement && <Tab label='TRIM MANAGEMENT' className='tab' value="trim" key="trimTab"/>}
          </Tabs>
        }
      </AppBar>
      { tabValue === 'updates' && <UpdatesAvailable /> }
      { trimManagement && tabValue === 'trim' && <TrimManagement /> }
    </>
  );
}
