import React, { useState }  from 'react';
import { useAuth } from 'components/Contexts/AuthContext';
import { Box, List } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import isSameSecond from 'date-fns/isSameSecond';
import parseISO from 'date-fns/parseISO';
import isBefore from 'date-fns/isBefore';
import Button from '@material-ui/core/Button';
import api from 'utils/api';

import CommnentView from './Comment-View';
import './AcknowledgeComments.scss';

const AcknowledgeCommentsView = ({
  addComment,
  comments,
  busId,
  busName,
  alertDate,
  textFieldValue,
  handleTextFieldChange,
  isLoading,
  isEnabled
}) => {
    // track if we have already sent metaCx; we only do it once when we start a new comment
    const { userID, customerList } = useAuth();
    const [hasSentMetaCx,setHasSentMetaCx]=useState(false);
    const sendMetaCx = (value) => {
      handleTextFieldChange(value);
      if (!hasSentMetaCx)
      {
        // metaCx event
        api.metaCx.addCommment({
          userId: userID,
          eventKey: 'faultalertcomment_created',
          source: 'external',
          eventDate: Date.now(),
          companyName: '',
          companyId: customerList,
          busId,
          busName,
          alerttimestamp: Date.parse(alertDate)
        }).then((response)=>{
          if (response?.status !== 204)
          {
            console.log('metaCx did not return 204.');
          }
        });
        setHasSentMetaCx(true);
      }
    }
    const sortedComments = comments.sort((a, b) => {
      const sameValue = isSameSecond(
        parseISO(a.commentDate),
        parseISO(b.commentDate)
      );
      const result = isBefore(parseISO(a.commentDate), parseISO(b.commentDate));
      if (result || sameValue) {
        return -1;
      }
      return 1;
    });
    return (
      <div className='ack-comment-container'>
        <div>
          <span className='proximanova-text comment-text'>Comments</span>
          {comments.length === 0 && (
            <div>
              <span className='proximanova-text no-comment-text'>
                There are currently no comments associate to this alert
              </span>
            </div>
          )}
          {isLoading ? (
            <div className='page-loader'>
              <CircularProgress />
            </div>
          ) : (
            <List className='comment-list-container'>
              {sortedComments.map((item, index) => (
                <CommnentView key={index.toString()} comment={item} />
              ))}
            </List>
          )}
        </div>
        <Box className='text-container'>
          <TextField
            InputProps={{
              disableUnderline: true,
              multiline: true,
              rows: 4
            }}
            maxLength='2'
            type='text'
            value={textFieldValue}
            className='text-field'
            onChange={e => sendMetaCx(e.target.value)}
          />
          <Button
            disabled={!isEnabled}
            variant='contained'
            className='add-new-comment proximanova-text'
            onClick={() => {
              handleTextFieldChange('');
              addComment();
            }}
            style={
              !isEnabled
                ? { backgroundColor: 'grey' }
                : { backgroundColor: '#3f51b5' }
            }
          >
            ADD NEW COMMENT
          </Button>
        </Box>
      </div>
    );
  };

export default AcknowledgeCommentsView;
