import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { StylesProvider } from '@material-ui/styles';

import { useAuth } from 'components/Contexts/AuthContext';
import { FeatureFlagProvider } from './Contexts/FeatureFlagContext';
import { BusContextProvider } from './Contexts/BusContext';
import '../styles/normalize.css';
import '../styles/index.scss';
import { initGA, pageView } from '../utils/analyticsTracking';
import { Sidebar } from './Shared/Sidebar/Sidebar.jsx';
import { Routes } from './Routes';


export const App = ({ config }) => {
  const { setLastAction, userID } = useAuth();
  const history = useRef(useHistory());
  const updateActivity = useRef(setLastAction);

  initGA(config.trackingId, userID)

  useEffect(() => {
    updateActivity.current();
    pageView();
    const unlisten = history.current.listen(() => {
      updateActivity.current();
      pageView();
    });

    return(() => {
      unlisten();
    });
  }, []);

  return (
    <BusContextProvider>
      <StylesProvider injectFirst>
        <FeatureFlagProvider>
          <div onClick={setLastAction} role="presentation">
            <Sidebar />
            <div className='main-content'>
              <Routes />
            </div>
          </div>
        </FeatureFlagProvider>
      </StylesProvider>
    </BusContextProvider>
  );
};

App.propTypes = {
  config: PropTypes.object
}
