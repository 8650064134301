import React from 'react';
import { Paper, Button, Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import SnackBarAlert from '../../Shared/SnackBarAlert';
import BusList from './BusList';
import RenderAlerts from './RenderAlerts';
import './Bus.scss';

const BusView = ({
    activeAlertData,
    inActiveIssues,
    equipmentStatus,
    allPriorityFaults,
    handleAlerts,
    customerNameData,
    averageKM
}) => {
    let hideMyActiveAlerts = false;
    if (activeAlertData) {
        const myActiveAlerts = allPriorityFaults.map(item =>
            item.filter(alert => alert.isDisplay && !alert.isAcknowledge)
        );

        hideMyActiveAlerts = !myActiveAlerts.length;
        if (myActiveAlerts.length) {
            hideMyActiveAlerts =
                !myActiveAlerts[0].length &&
                !myActiveAlerts[1].length &&
                !myActiveAlerts[2].length &&
                !myActiveAlerts[3].length;
        }
    }

    return (
        <div className='bus-page-div'>
            {hideMyActiveAlerts && (
                <SnackBarAlert
                    className='snackbar'
                    message='Congratulations, your fleet has no active alerts at this time'
                />
            )}
            {activeAlertData ? (
                <Grid container spacing={2} className='grid-container'>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={hideMyActiveAlerts ? 12 : 7}
                        lg={hideMyActiveAlerts ? 12 : 9}
                    >
                        <Paper>
                            <div className='bus-list-container'>
                                <div className='title'>Electric Buses</div>
                                <BusList
                                    engineIssues={activeAlertData}
                                    inActiveIssues={inActiveIssues}
                                    equipmentStatus={equipmentStatus}
                                    customerNameData={customerNameData}
                                    averageKM={averageKM}
                                />
                            </div>
                        </Paper>
                    </Grid>
                    {!hideMyActiveAlerts && (
                        <Grid item xs={12} sm={12} md={5} lg={3}>
                            <Paper>
                                <div className='title'>My Active Alerts</div>
                                {allPriorityFaults.map((item, index) => (
                                    <RenderAlerts
                                        item={item}
                                        index={index}
                                        key={index}
                                    />
                                ))}
                                <Button
                                    className='ev-btn-alerts proximanova-bold'
                                    onClick={handleAlerts}
                                >
                                    View All My Alerts
                                </Button>
                            </Paper>
                        </Grid>
                    )}
                </Grid>
            ) : (
                <div className='page-loader'>
                    <CircularProgress />
                </div>
            )}
        </div>
    );
};

export default BusView;
