import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import './SnackBarAlert.scss';

export default class SnackBarAlertView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true
    };
  }

  handleClose = () => {
    this.setState({ isOpen: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  render() {
    const { message, className, messageClassName } = this.props;
    const { isOpen } = this.state;

    if (!isOpen) return null;

    return (
      <div className={className}>
        <SnackbarContent
          className={`success ${messageClassName}`}
          aria-describedby='client-snackbar'
          message={
            <span id='client-snackbar' className='message'>
              <CheckCircleIcon className='icon' />
              {message}
            </span>
          }
          action={[
            <IconButton
              key='close'
              aria-label='Close'
              color='inherit'
              onClick={this.handleClose}
            >
              <CloseIcon className='icon' />
            </IconButton>
          ]}
        />
      </div>
    );
  }
}
