/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { BusContext } from '../../../Contexts/BusContext';
import BusList from './BusList-View';

class BusListContainer extends Component {
  static contextType = BusContext;

  constructor() {
    super();
    this.state = {
      zoom: 1,
      activeIndex: -1,
      initialzoom: 1,
      lat: null,
      lng: null
    };
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this._map = null;
    this._bounds = null;
  }

  onMarkerClick = (item, index) => {
    const lat = parseFloat(item.latitude.S);
    const lng = parseFloat(item.longitude.S);
    if (this.state.activeIndex === index) {
      this.props.onMarkerSecondClick(item);
    } else {
      this.setState({ activeIndex: index, lat, lng });
    }
  };

  onCloseIconClick = () => {
    this.setState(prevState => ({
      activeIndex: -1,
      zoom: prevState.initialzoom
    }));
  };

  handleBusNavigation = bus => {
    const busData = this.context;
    const { equipmentStatus, averageKM } = busData;
    this.props.history.push('/bus-details', {
      customerEquipId: bus.customerEquipId.S,
      busDetails: {
        ...bus
      },
      equipmentStatus,
      averageKM
    });
  };

  getCount = value => {
    if (!this.context.customerNameData || !this.context.customerNameData.engine) {
      return 0; 
    }
    const count = Object.keys(this.context.customerNameData.engine).filter(
      engine => this.context.customerNameData.engine[engine].equipId === value
    );
    return count.length
      ? this.context.customerNameData.engine[count[0]].count
      : 0;
  };

  render() {
    const { equipmentStatus, engineIssues, inActiveIssues } = this.props;
    return equipmentStatus ? (
      <BusList
        equipmentStatus={equipmentStatus}
        engineIssues={engineIssues}
        inActiveIssues={inActiveIssues}
        handleBusNavigation={this.handleBusNavigation}
        getCount={value => this.getCount(value)}
        onMarkerSecondClick={this.onMarkerSecondClick}
      />
    ) : (
      'Loading ...'
    );
  }
}

export default withRouter(BusListContainer);
