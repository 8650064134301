/**
 * Format a date in the local time zone
 * @param date
 * @returns {string}
 */
export const formatDateTimeToLocal = date => {
  const currentDate = new Date(date);
  const utcDate = new Date(
    Date.UTC(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      currentDate.getHours(),
      currentDate.getMinutes(),
      currentDate.getSeconds()
    )
  );
  return utcDate.toLocaleString();
};
