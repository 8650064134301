import React, { Component } from 'react';
import Auth from '@aws-amplify/auth';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Checkbox from '@material-ui/core/Checkbox';
import * as Constants from './utils/constants';
import CumminsLogo from './assets/Icons/logo_cummins.svg';
import PreventechLogo from './assets/Icons/PrevenTech_EV_OneLine_White.png';

export class LandingScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupVisible: false,
      checkboxChecked: false,
      guidelinesClicked: false
    };
  }

  handleClick = async () => {
    const route = (window.location.pathname + window.location.search).substr(1);
    try {
      await Auth.federatedSignIn({
        provider: this.props.providerName,
        customState: route
      });
    } catch (error) {
      console.error('Error during federated sign-in:', error);
    }
  };

  handleCheckboxChange = () => {
    this.setState(prevState => ({
      checkboxChecked: !prevState.checkboxChecked
    }));
  };

  handleOkClick = () => {
    this.setState({ popupVisible: false });
    window.location.href =
      'https://mylogin.cummins.com/web/IAM_NewRegistration?appid=a1a6100000AI0uY';
  };

  handleSignUpClick = () => {
    this.setState({ popupVisible: true });
  };

  handlePopupDialogClose = () => {
    this.setState({ popupVisible: false });
  };

  handleDownloadGuide = event => {
    event.preventDefault();
    this.setState({
      guidelinesClicked: true
    });
    const pdfUrl = '/PrevenTechRegistrationGuidelines.pdf';
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = 'PrevenTechRegistrationGuidelines.pdf'; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  render() {
    const { popupVisible, checkboxChecked, guidelinesClicked } = this.state;
    return (
      <div>
        <nav className='navbar navbar-expand-lg navbar-light navbar-color'>
          <a className='navbar-brand' href='#'>
              <img src={CumminsLogo} alt='cumminsLogo' />
              <span className='ml-3'><img src={PreventechLogo} alt='preventech ev logo' width="155px"/></span>
          </a>
        </nav>
        <div className='container'>
          <div className='row align-items-center row-height'>
            <div className='col text-center'>
              <h2>{Constants.HAVEACCOUNTTEXT}</h2>
              <Button
                id='login'
                variant='contained'
                onClick={this.handleClick}
                className='btn btn-lg btn-login'
              >
                {Constants.LOGINBUTTONTEXT}
              </Button>
            </div>
            <div className='vertical-line' />
            <div className='col text-center'>
              <h2>{Constants.NEWUSERTEXT}</h2>
              <Button
                id='signup'
                variant='contained'
                color='primary'
                onClick={this.handleSignUpClick}
                className='btn btn-lg btn-signup'
              >
                {Constants.SIGNUPBUTTONTEXT}
              </Button>
            </div>
          </div>
        </div>
        <Dialog open={popupVisible} onClose={this.handlePopupDialogClose}>
          <DialogTitle
            style={{
              backgroundColor: '#ef1f1c',
              color: 'white',
              fontWeight: 'bold'
            }}
          >
            Information
          </DialogTitle>
          <DialogContent>
            <p style={{ color: 'black' }}>
              New customers are requested to download and review the
              registration guide before beginning the registration process.
              Failure to review and implement the instructions "as-is" could
              result in faulty registration.
            </p>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Checkbox
                id='Checkbox'
                color="success"
                checked={checkboxChecked}
                onChange={this.handleCheckboxChange}
                inputProps={{ 'aria-label': 'Open PDF' }}
                disabled={!guidelinesClicked}
                style={{ color: 'green' }}
              />
              <a
                id='anchorTag'
                href='/PrevenTechRegistrationGuidelines.pdf'
                download
                onClick={this.handleDownloadGuide}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                I have read the{' '}
                <sapn
                  style={{ textDecoration: 'underline', fontWeight: 'bold' }}
                >
                  registration guide
                </sapn>
              </a>
            </div>
          </DialogContent>
          <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              id='popupButton'
              onClick={this.handleOkClick}
              color='primary'
              disabled={!checkboxChecked}
              style={{
                borderRadius: '12px',
                backgroundColor: '#008080',
                color: 'white',
                fontSize: 'medium',
                width: '30%',
                opacity: checkboxChecked ? 1 : 0.5,
                pointerEvents: checkboxChecked ? 'auto' : 'none'
              }}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
