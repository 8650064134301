import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { useBus } from 'components/Contexts/BusContext';
import IconElectricity from '../../../../assets/Icons/icon_electricity.svg';

const useToolbarStyles = makeStyles((theme) => ({
	root: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(1)
	},
	highlight: {
		color: '#3f51b5',
		backgroundColor: '#e8eaf6'
	},
	spacer: {
		flex: '1 1 100%'
	},
	title: {
		flex: '0 0 auto'
	}
}));

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(3)
	},
	paper: {
		marginBottom: theme.spacing(2)
	},
	tableWrapper: {
		overflowX: 'auto'
	}
}));

export default function UpdatesTable(props) {
	const classes = useStyles();
	const { busStatus } = useBus();
	const { handleSelectAllClick, handleCheckboxClick, STATUS, clickOpen, fleetData } = props;
	let selectedCount = 0;
	fleetData.forEach(item => {
		if (item.isSelected) {
			selectedCount += 1;
		}
	});
	const headRows = [
		{ id: 'busID', label: 'Bus' },
		{
			id: 'currentVersion',
			label: 'Current Version'
		},
		{
			id: 'availableVersion',
			label: 'Available Version'
		},
		{ id: 'button', label: '' }
	];

	const ApproveButton = ({ bus, disabled, type }) => {
		let cssClassName = 'approve-btn';
		let label = 'Approve';
		if (type === 'approve-all') {
			cssClassName = 'approve-all-btn';
			label = 'Approve Selected';
		}
		return (
			<Button
				variant="contained"
				color="primary"
				className={cssClassName}
				onClick={() => clickOpen(bus)}
				disabled={disabled}
			>
				{label}
			</Button>
		);
	};

	function EnhancedTableHead(myprops) {
		const { onSelectAllClick, numSelected, rowCount } = myprops;
		return (
			<TableHead>
				<TableRow className="approval-tableRow">
					<TableCell padding="checkbox">
						<Checkbox
							color="secondary"
							indeterminate={numSelected > 0 && numSelected < rowCount}
							checked={numSelected === rowCount}
							onChange={onSelectAllClick}
							inputProps={{ 'aria-label': 'Select all desserts' }}
						/>
					</TableCell>
					{headRows.map((row) => (
						<TableCell key={row.id} align="left">
							{row.label}
						</TableCell>
					))}
				</TableRow>
			</TableHead>
		);
	}

	const EnhancedTableToolbar = (myprops) => {
		const toolbarClasses = useToolbarStyles();
		const { numSelected } = myprops;

		return (
			<Toolbar
				className={clsx(toolbarClasses.root, {
					[toolbarClasses.highlight]: numSelected > 0
				})}
			>
				<div className={toolbarClasses.title}>
					{numSelected > 0 ? (
						<Typography color="inherit" variant="subtitle1">
							{numSelected} selected
						</Typography>
					) : (
						<p className="table-title">Needs Approval</p>
					)}
				</div>
				<div className={toolbarClasses.spacer} />
				<div>
					{numSelected > 0 && <ApproveButton type="approve-all" disabled={false} />}
				</div>
			</Toolbar>
		);
	};

	return (
		<div className={classes.root}>
			<Paper className={classes.paper}>
				<EnhancedTableToolbar numSelected={selectedCount} />
				<div className={classes.tableWrapper}>
					<Table className={classes.table}>
						<EnhancedTableHead
							numSelected={selectedCount}
							onSelectAllClick={handleSelectAllClick}
							rowCount={fleetData.length}
						/>
						<TableBody>
							{fleetData.map((row) => {
								if (row.calStatus !== STATUS.WAITINGAPPROVAL) {
									return;
								}
								const isItemSelected = row.isSelected;
								return (
									<TableRow
										hover
										role="checkbox"
										aria-checked={isItemSelected}
										tabIndex={-1}
										key={row.engineSerialNum}
										selected={isItemSelected}
										className="approval-tableRow"
									>
										<TableCell padding="checkbox">
											<Checkbox
												color="secondary"
												checked={isItemSelected}
												onClick={(event) => handleCheckboxClick(event, row)}
											/>
										</TableCell>
										<TableCell component="th" scope="row" padding="default">
											<div>
												<img
													src={IconElectricity}
													className="icon-electricity"
													alt='electricity icon'
												/>
												<span className="update-list-bus-name">
													{ busStatus && busStatus[row.engineSerialNum]
                            ? busStatus[row.engineSerialNum].customerEquipId
                            : row.engineSerialNum
													}
												</span>
											</div>
										</TableCell>
										<TableCell align="left" padding="default">
											{row.currentCalRevision}
										</TableCell>
										<TableCell align="left" padding="default">
											<strong>{row.pendingCalRevision}</strong>
										</TableCell>
										<TableCell align="left">
											<ApproveButton disabled={isItemSelected} bus={row} />
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</div>
			</Paper>
		</div>
	);
}
