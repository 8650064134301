import React, { useContext, useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useParams } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Card } from '@material-ui/core';
import { useHistory } from 'react-router';
import { diffTime } from 'utils/differenceInTime';
import { BusContext } from '../../Contexts/BusContext';
import './BusDetail.scss';
import { ElectricityIcon } from '../../Shared/customIcons';
import { Print } from '../Analytics/Print/Print';
import { FaultsPagedTable } from '../../Shared/FaultsPagedTable/FaultsPagedTable';
import { Today } from './Today/Today';
import useBusPath from 'hooks/useBusPath'

export const BusDetail = () => {
  const { esn, tab = 'today' } = useParams();
  const [tabValue, setTabValue] = useState(tab);
  const history = useHistory();
  const { busStatus, averageKM } = useContext(BusContext);
  const busPath = useBusPath()

  // When the user changes tab, update the history/URL.
  // The `useEffect` below will then change the component's state.
  const changeTab = ( e, newValue ) => {
    history.push(`${busPath}/${esn}/${newValue}`);
  };

  // This will watch the `tab` path param and change state accordingly. Without
  // this, the back/forward browser buttons won't trigger a state change and
  // won't change the tab.
  useEffect(() => {
    setTabValue(tab);
  }, [tab]);

  let busData;
  if (busStatus) {
    busData = busStatus[esn];
  }

  if(averageKM && averageKM[esn]) {
    busData.kWhpermi = averageKM[esn].kWhpermi;
  }


  return busData ? (
    <>
      <Card>
        <div className='paper-selected'>
          <div className='bus-name'>
            <ElectricityIcon />
            <span>&nbsp;{busData.customerEquipId}</span>
          </div>
          <div className='selected-bus-details'>
            <span>
              SSN: <span>{busData.esn}</span>
            </span>
            <span>
              SMN: <span>{busData.serviceModelName}</span>
            </span>
            <span>
              VIN: <span>{busData.equipVin ? busData.equipVin : null}</span>
            </span>
            <span>
              Last data Sync: <span>{diffTime(busData.msgTimestamp)}</span>
            </span>
          </div>
        </div>
      </Card>
      <AppBar position='static' className='tab-bar'>
        <Tabs value={tabValue} onChange={changeTab}>
          <Tab label='TODAY' className='tab' value='today' />
          <Tab label='FAULTS' className='tab' value='issues' />
          <Tab label='Performance' className='tab' value='performance' />
        </Tabs>
      </AppBar>
      { tabValue === 'today' && <Today busData={busData} /> }
      { tabValue === 'issues' && <FaultsPagedTable faults={busData.faults} isSingleBus /> }
      { tabValue === 'performance' && <Print esn={busData.esn} isSingleBus /> }
    </>
  ) : (
    <div className='page-loader'>
      <CircularProgress />
    </div>
  );
};
