import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import RenderAlerts from './RenderAlerts-View';

class RenderAlertsContainer extends Component {
  state = {
    type: '',
    styledClass: '',
    count: 0,
    countClass: ''
  };

  componentDidMount() {
    this.evaluateAlerts(this.props.item || []);
  }

  evaluateAlerts = item => {
    let type;
    let styledClass;
    let count = 0;
    let countClass;
    let items = [];

    items = item.filter(i => i.isDisplay && !i.isAcknowledge);
    switch (this.props.index) {
      case 0:
        type = 'Immediate';
        count = items && items.length;
        styledClass = 'service-immediate service-notification';
        countClass = 'service-immediate counter-rad';
        break;
      case 1:
        type = 'Service Within 3 Days';
        count = items && items.length;
        styledClass = 'service-notification service-notification-3';
        countClass = 'service-notification-3 counter-rad';
        break;
      case 2:
        type = 'Service Within 7 Days';
        count = items && items.length;
        styledClass = 'service-notification service-notification-7';
        countClass = 'service-notification-7 counter-rad';
        break;
      case 3:
        type = 'Next Planned Maintenance';
        count = items && items.length;
        styledClass = 'service-notification service-notification-planned';
        countClass = 'service-notification-planned counter-rad';
        break;
      default:
        return;
    }
    this.setState({ type, styledClass, count, countClass });
  };

  render() {
    const { type, styledClass, count, countClass } = this.state;
    return (
      <RenderAlerts
        type={type}
        styledClass={styledClass}
        count={count}
        countClass={countClass}
        faults={this.props.item || []}
      />
    );
  }
}

export default withRouter(RenderAlertsContainer);
