import React, { Component } from 'react';
import { format } from 'date-fns';

import { logEvent } from 'utils/analyticsTracking';
import AcknowledgeComments from './AcknowledgeComments-View';
import api from '../../../utils/api';
import { AuthContext } from '../../Contexts/AuthContext';

function countWords(comment){
  const resultArray = [];
  // eslint-disable-next-line no-useless-escape
  const str = comment.replace(/[\t\n\r\.\?\!]/gm,' ');
  const wordArray = str.split(' ');
  for (let i = 0; i < wordArray.length; i+=1) {
    const item = wordArray[i].trim();
    if(item.length > 0){
      resultArray.push(item);
    }
  }
  return resultArray.length;
}

class AcknowledgeCommentsContainer extends Component {
  static contextType = AuthContext;

  state = {
    comments: [],
    textFieldValue: '',
    isLoading: false
  };

  componentDidMount() {
    this.getComment();
  }

  getComment = async () => {
    this.setState({
      isLoading: true
    });
    const { faultDetails, busDetails } = this.props;
    const customer = this.context.customerList;
    const comments = await api.faultComments.getAll({
      faultID: faultDetails.faultID,
      fmi: faultDetails.fmi,
      spn: faultDetails.spn,
      dateOfOccurence: faultDetails.dateOfOccurence,
      engineSerialNumber: faultDetails.esn,
      serviceModelName: busDetails.serviceModelName,
      customerFK: customer
    });
    this.setState({
      comments: [...comments],
      isLoading: false
    });
  };

  addComment = async () => {
    logEvent({
      action: 'click',
      category: 'Comment',
      label: 'Add New Comment'
    });

    const { faultDetails, busDetails } = this.props;
    const customer = this.context.customerList;
    const comment = this.state.textFieldValue.slice();
    
    // metaCx event
    const metaCx=await api.metaCx.addCommment({
      userId: this.context.userID,
      eventKey: 'faultalertcomment_submitted',
      source: 'external',
      eventDate: Date.now(),
      companyName: '',
      companyId: customer,
      busId: faultDetails.esn,
      busName: busDetails.customerEquipId,
      alerttimestamp: Date.parse(faultDetails.dateOfOccurence),
      commenttimestamp: Date.now(),
      commentwordcount: countWords(comment)
    });

    if (metaCx?.status !== 204)
    {
      console.log('metaCx did not return 204.');
    }

    const addCommentResponse = await api.faultComments.add({
      faultID: faultDetails.faultID,
      fmi: faultDetails.fmi,
      spn: faultDetails.spn,
      dateOfOccurence: faultDetails.dateOfOccurence,
      engineSerialNumber: faultDetails.esn,
      serviceModelName: busDetails.serviceModelName,
      commentDate: format(new Date(), 'yyyy-MM-dd  hh:mm:ss'),
      customerFK: customer,
      firstName: this.context.givenName,
      lastName: this.context.familyName,
      uComment: comment,
      userID: this.context.userID,
      emailAddress: this.context.username
    });
    if (addCommentResponse === 'Comment Posted.') {
      this.getComment();
      this.setState({ textFieldValue: '' });
    }
  };

  handleTextFieldChange = value => {
    this.setState({ textFieldValue: value });
  };

  render() {
    const { faultDetails, busDetails } = this.props;
    const isEnabled = this.state.textFieldValue.length > 0;
    return (
      <AcknowledgeComments
        addComment={this.addComment}
        comments={this.state.comments}
        busId={faultDetails.esn}
        busName={busDetails.customerEquipId}
        alertDate={faultDetails.dateOfOccurence}
        textFieldValue={this.state.textFieldValue}
        handleTextFieldChange={this.handleTextFieldChange}
        isLoading={this.state.isLoading}
        isEnabled={isEnabled}
      />
    );
  }
}

export default AcknowledgeCommentsContainer;
