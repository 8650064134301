import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Paper, TextField } from '@material-ui/core';

import { useForm } from 'hooks/useFormHook';
import { FieldErrors } from 'components/Shared/FieldErrors/FieldErrors.jsx';
import { validations } from './trimManagementFormValidations.js';
import './TrimManagementForm.module.css';

const getTrimStatus = (vehicleTrims) => {
  const trimStatus = {
    tireSize: [],
    variableAccelAccel1: [],
    variableAccelAccel2: [],
    variableAccelSpeed1: [],
    variableAccelSpeed2: [],
    maxRoadSpeedTrim2: [],
    globalRoadSpeedMaximum: [],
    outerTargetVoltageValue: [],
    lowSocPowerAdjustmentEnable: []
  };

  vehicleTrims.forEach((trim) => {
    trimStatus.tireSize.push({ customerEquipId: trim.customerEquipId, ssn: trim.ssn, value: trim.trim.tireSize });
    trimStatus.variableAccelAccel1.push({ customerEquipId: trim.customerEquipId, ssn: trim.ssn, value: trim.trim.variableAccelAccel1 });
    trimStatus.variableAccelAccel2.push({ customerEquipId: trim.customerEquipId, ssn: trim.ssn, value: trim.trim.variableAccelAccel2 });
    trimStatus.variableAccelSpeed1.push({ customerEquipId: trim.customerEquipId, ssn: trim.ssn, value: trim.trim.variableAccelSpeed1 });
    trimStatus.variableAccelSpeed2.push({ customerEquipId: trim.customerEquipId, ssn: trim.ssn, value: trim.trim.variableAccelSpeed2 });
    trimStatus.maxRoadSpeedTrim2.push({ customerEquipId: trim.customerEquipId, ssn: trim.ssn, value: trim.trim.maxRoadSpeedTrim2 });
    trimStatus.globalRoadSpeedMaximum.push({ customerEquipId: trim.customerEquipId, ssn: trim.ssn, value: trim.trim.globalRoadSpeedMaximum });
    trimStatus.outerTargetVoltageValue.push({ customerEquipId: trim.customerEquipId, ssn: trim.ssn, value: trim.trim.outerTargetVoltageValue });
    trimStatus.lowSocPowerAdjustmentEnable.push({ customerEquipId: trim.customerEquipId, ssn: trim.ssn, value: trim.trim.lowSocPowerAdjustmentEnable });
  });

  return trimStatus;
};

const CurrentStatus = ({ unit, values }) => {
  if (!values) {
    return null;
  }
  if (values.length === 1) {
    return (
      <div>
        <span className="vehicleValue">{ values[0].value } { unit }</span>
      </div>
    );
  }

  return (
    values.map((val) => (
        <div key={val.ssn}>
          <span className="vehicleDesignation">{ val.customerEquipId }</span>
          <span className="vehicleValue">{ val.value } { unit }</span>
        </div>
      ))
  );
};

CurrentStatus.propTypes = {
  unit: PropTypes.string.isRequired,
  values: PropTypes.array
};

export const TrimManagementForm = ({ onCancel, onSubmit, vehicleTrims }) => {
  const [currentStatus, setCurrentStatus] = useState({});
  const formRef = useRef(null);
  const { errors, handleChange, handleSubmit } = useForm({
    initialValues: {
      tireSize: null,
      variableAccelAccel1: null,
      variableAccelAccel2: null,
      variableAccelSpeed1: null,
      variableAccelSpeed2: null,
      maxRoadSpeedTrim2: null,
      globalRoadSpeedMaximum: null
      // outerTargetVoltageValue: null,
      // lowSocPowerAdjustmentEnable: null
    },
    validations,
    onSubmit
  });

  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      left: 0,
      top: formRef.current.offsetTop - 70
    });
  }, [formRef]);

  useEffect(() => {
    setCurrentStatus(getTrimStatus(vehicleTrims));
  }, [vehicleTrims]);

  const handleCancel = (e) => {
    e.preventDefault();
    onCancel();
  };

  return (
    <Paper className="trimManagementForm" ref={formRef}>
      <div className="stepHeader">
        <div className="step current">2</div>
        <div className="stepInstruction">Select your updates for this vehicle:</div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="trimColumnHeaders">
          <div/>
          <div>Current Status</div>
          <div>New Status</div>
        </div>
        <div>
          <div className="trimSectionHeader">Tires</div>
          <div className="trimRow">
            <div>Tires</div>
            <div className="trimTitle">Current Status</div>
            <div><CurrentStatus unit="rev/km" values={currentStatus.tireSize}/></div>
            <div className="trimTitle">New Status</div>
            <div>
              <TextField
                className="trimControl"
                name="tireSize"
                onChange={handleChange}
                variant="outlined"
              />
              <FieldErrors errors={errors.tireSize} />
            </div>
          </div>
        </div>
        <div>
          <div className="trimSectionHeader">VAM</div>
          <div className="trimRow">
            <div>VAM VariableAccel1</div>
            <div className="trimTitle">Current Status</div>
            <div><CurrentStatus unit="km/hr/sec" values={currentStatus.variableAccelAccel1}/></div>
            <div className="trimTitle">New Status</div>
            <div>
              <TextField
                className="trimControl"
                name="variableAccelAccel1"
                onChange={handleChange}
                variant="outlined"
              />
              <FieldErrors errors={errors.variableAccelAccel1} />
            </div>
          </div>
          <div className="trimRow">
            <div>VAM VariableAccel2</div>
            <div className="trimTitle">Current Status</div>
            <div><CurrentStatus unit="km/hr/sec" values={currentStatus.variableAccelAccel2}/></div>
            <div className="trimTitle">New Status</div>
            <div>
              <TextField
                className="trimControl"
                name="variableAccelAccel2"
                onChange={handleChange}
                variant="outlined"
              />
              <FieldErrors errors={errors.variableAccelAccel2} />
            </div>
          </div>
          <div className="trimRow">
            <div>VAM VariableAccel_Speed1</div>
            <div className="trimTitle">Current Status</div>
            <div><CurrentStatus unit="km/hr/sec" values={currentStatus.variableAccelSpeed1}/></div>
            <div className="trimTitle">New Status</div>
            <div>
              <TextField
                className="trimControl"
                name="variableAccelSpeed1"
                onChange={handleChange}
                variant="outlined"
              />
              <FieldErrors errors={errors.variableAccelSpeed1} />
            </div>
          </div>
          <div className="trimRow">
            <div>VAM VariableAccel_Speed2</div>
            <div className="trimTitle">Current Status</div>
            <div><CurrentStatus unit="km/hr/sec" values={currentStatus.variableAccelSpeed2}/></div>
            <div className="trimTitle">New Status</div>
            <div>
              <TextField
                className="trimControl"
                name="variableAccelSpeed2"
                onChange={handleChange}
                variant="outlined"
              />
              <FieldErrors errors={errors.variableAccelSpeed2} />
            </div>
          </div>
        </div>
        <div>
          <div className="trimSectionHeader">SMVS</div>
          <div className="trimRow">
            <div>Max Road Speed Trim 2 Version 2</div>
            <div className="trimTitle">Current Status</div>
            <div><CurrentStatus unit="km/hr" values={currentStatus.maxRoadSpeedTrim2}/></div>
            <div className="trimTitle">New Status</div>
            <div>
              <TextField
                className="trimControl"
                name="maxRoadSpeedTrim2"
                onChange={handleChange}
                variant="outlined"
              />
              <FieldErrors errors={errors.maxRoadSpeedTrim2} />
            </div>
          </div>
        </div>
        <div>
          <div className="trimSectionHeader">RSC</div>
          <div className="trimRow">
            <div>Global Road Speed Maximum</div>
            <div className="trimTitle">Current Status</div>
            <div><CurrentStatus unit="km/hr" values={currentStatus.globalRoadSpeedMaximum}/></div>
            <div className="trimTitle">New Status</div>
            <div>
              <TextField
                className="trimControl"
                name="globalRoadSpeedMaximum"
                onChange={handleChange}
                variant="outlined"
              />
              <FieldErrors errors={errors.globalRoadSpeedMaximum} />
            </div>
          </div>
        </div>
        <div className="trimFormButtons">
          <Button
            onClick={handleCancel}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={handleSubmit}
            variant="contained"
          >
            Submit Adjustments
          </Button>
        </div>
      </form>
    </Paper>
  );
};

TrimManagementForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  vehicleTrims: PropTypes.array.isRequired
};
