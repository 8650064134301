import React, { Fragment } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';

import './DropDownButton.scss';
import { useAuth } from 'components/Contexts/AuthContext';

const DropDownButtonView = ({ handleClickAway, handleClick, buttonName, avatar, open }) => {
  const { signOut } = useAuth();

  const handleSignOut = () => {
    handleClickAway();
    signOut();
  };

  return (
    <div className="drop-container">
      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          <Button onClick={handleClick} className="open-drop-down">
            <span>{buttonName}</span>
            {open ? (
              <i className="fas fa-angle-up fa-lg" />
            ) : (
              <i className="fas fa-angle-down fa-lg" />
            )}
          </Button>
          {open && (
            <Paper className="drop-paper">
              {avatar && (
                <Fragment>
                  <Link className="drop-item" to="/manage-alerts" onClick={handleClickAway}>
                    <i className="fas fa-exclamation-triangle" />
                    <span>Manage Alerts</span>
                  </Link>
                  <Link className="drop-item" to="#" onClick={handleSignOut} tabIndex="0" >
                    <i className="fas fa-sign-out-alt" />
                    <span>Logout</span>
                  </Link>
                </Fragment>
              )}
            </Paper>
          )}
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default DropDownButtonView;
