import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { useLocation, useRouteMatch } from 'react-router-dom';
import DropDownButton from './DropDownButton';
import './HeaderApp.scss';
import { AuthContext } from '../../../Contexts/AuthContext';
import { BusContext } from '../../../Contexts/BusContext';
import { useFeatureFlags } from '../../../Contexts/FeatureFlagContext';

function HeaderAppView({ drawerToggle }) {

    const flags = useFeatureFlags();
    const isBuses = useRouteMatch('/assets/buses');
    const isChargers = useRouteMatch('/assets/chargers');

	  const location = useLocation();

	  /* The whole idea of having the title be taken from the URL is hacky and
	   * won't be valid for i18n (unless we use it as a key). Also, I needed to
	   * add a hack to the hack for nested paths.
	   * - JRB
	   */
	  const slash = location.pathname.indexOf('/', 1);
	  let header;
	  if (slash >= 0) {
	      header = location.pathname.substring(1, slash).replace(/-/g, ' ');
    } else {
        header = location.pathname.slice(1).replace(/-/g, ' ')
    }
    if(flags.chargeManagement) {
        if(isBuses) {
            header = 'Buses';
        }
        else if (isChargers) {
            header = 'Chargers';
        }
    }
    return (
        <AuthContext.Consumer>
            {userContext => (
                <BusContext.Consumer>
                    {busContext => (
                        <AppBar
                            position='fixed'
                            color='default'
                            className='appBar'
                        >
                            <Hidden mdUp>
                                <IconButton
                                    color='inherit'
                                    onClick={drawerToggle}
                                    className='menu-button'
                                >
                                    <MenuIcon />
                                </IconButton>
                            </Hidden>
                            <h4 className='header-name'>{header}</h4>
                            <span className='customer-name'>
                                {busContext.customerNameData
                                    ? busContext.customerNameData.custName
                                    : 'Loading'}
                            </span>
                            <DropDownButton
                                buttonName={`${userContext.givenName} ${userContext.familyName}`}
                                avatar
                            />
                        </AppBar>
                    )}
                </BusContext.Consumer>
            )}
        </AuthContext.Consumer>
    );
}

export default HeaderAppView;
