/**
 * Check if a value is numeric
 * @param value {string|number}
 * @returns {Object}
 */
const validateNumeric = (value) => {
  const numeric = /^[-+]?[0-9]*\.?[0-9]+$/;
  if (value && value.match && !value.match(numeric)) {
    return 'Must be numeric';
  }
  return null;
};

/**
 * Validate that the value exists.
 * @param value {string}
 */
const validateRequired = (value) => {
  if (!value) {
    return 'Required'
  }
  return null;
};

/**
 * Validate the user input.
 * @param fields key/value pair of field inputs
 * @param validations key/value pair of validations for the fields
 *
 * @returns {Object}
 */
export const validateForm = (fields, validations) => {
  const fieldErrors = {
    hasErrors: false
  };
  const fieldNames = Object.keys(fields);
  fieldNames.forEach((field) => {
    // I'd prefer to use other methods here, but want to make sure IE doesn't blow up.
    // eslint-disable-next-line no-prototype-builtins
    if (fields.hasOwnProperty(field)) {
      const fieldValidations = validations[field];
      if (fieldValidations && fieldValidations.length) {
        const value = fields[field];
        fieldErrors[field] = [];
        fieldValidations.forEach((validation) => {

          let error;
          if (validation.type === 'numeric') {
            error = validateNumeric(value);
          } else if (validation.type === 'required') {
            error = validateRequired(value);
          } else {
            // Maybe this should throw an error.
            // Or maybe we should have better error reporting than console.
            console.error(`Validation "${validation.type}" is not supported.`);
          }
          if (error) {
            fieldErrors[field].push(error);
            fieldErrors.hasErrors = true;
          }
        });
      }
    }
  });
  return fieldErrors;
};
