import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

const FeatureFlagContext = React.createContext({});

// Figure out if there's an "overrides" param in the query string, and turn the
// comma-separated value into an array.
const parseOverrides = (queryString) => {
  let overrides = null;
  if (queryString) {
    const overridePosition = queryString.indexOf('override');
    if (overridePosition > -1) {
      const start = queryString.indexOf('=', overridePosition) + 1;
      const nextParam = queryString.indexOf('&');
      let overrideParam;
      if (nextParam === -1) {
        overrideParam = queryString.substring(start);
      } else {
        overrideParam = queryString.substring(start, nextParam);
      }
      overrides = overrideParam.split(',');
    }
  }
  return overrides;
};

// Get the overrides from the URL, and apply them to the configured flags. Any
// value in the comma-separated string will
const applyOverrides = (flags, queryString) => {
  const overrides = parseOverrides(queryString);
  const newFlags = {
    ...flags
  };
  if (overrides && overrides.length) {
    overrides.forEach((override) => {
      newFlags[override] = !flags[override];
    });
  }
  return newFlags;
};

const FeatureFlag = ({ children }) => {
  const [flags, setFlags] = useState({});
  const [flagsLoaded, setFlagsLoaded] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Future - retrieve from API instead of local config file
    if (!flagsLoaded) {
      // we don't want to call this again on a re-render
      setFlagsLoaded(true);
      fetch('/flags.json', { cache: 'no-store' })
      .then((response) => response.ok ? response.json() : Promise.reject())
      .then((jsonFlags) => {
        const newFlags = applyOverrides(jsonFlags, location.search);
        setFlags(newFlags);
      })
      .catch(() => {
        // set to an empty object, all checks will be false. we don't want a
        // failure to retrieve the flags to lead to hidden features being enabled.
        setFlags({});
      });
    }
  }, [location.search]);

  return (
    <FeatureFlagContext.Provider value={flags}>
      { children }
    </FeatureFlagContext.Provider>
  );
};

FeatureFlag.propTypes = {
  children: PropTypes.element.isRequired
};

export const FeatureFlagProvider = FeatureFlag;
export const useFeatureFlags = () => useContext(FeatureFlagContext);
export { FeatureFlagContext }
