import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import { format as formatDate, parseISO } from 'date-fns';
import api from 'utils/api';
import './SavedScenarios.module.css';

const SavedScenarios = () => {
  const [scenarios, setScenarios] = useState([]);
  const [pageState, setPageState] = useState('waiting');

  useEffect(() => {
    let active = true;
    api.simulation.getSimulations()
    .then((data) => {
      let newState = 'empty';
      if (data && data.length) {
        // server sorts FIFO, we want LIFO
        data.reverse();
        newState = 'loaded';
      }
      if (active) {
        setScenarios(data);
        setPageState(newState);
      }
    })
    .catch((error) => {
      setPageState('error');
    });

    return () => {
      // This kicks off when the component is unmounted.
      active = false;
    };
  }, []);

  return (
    <>
      { pageState === 'waiting' &&
        <div className="page-loader">
          <CircularProgress/>
        </div>
      }
      { pageState === 'empty' &&
        <Paper className="padded scenarios">
          <p>You have no saved scenarios. You can create scenarios in the <Link to="/range-estimation/scenario">Scenario</Link> tab.</p>
        </Paper>
      }
      { pageState === 'error' &&
        <Paper className="padded scenarios">
          <p>Unable to load saved scenarios.</p>
        </Paper>
      }
      { pageState === 'loaded' &&
        <TableContainer component={Paper} className="scenarios">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Scenario Name</TableCell>
                <TableCell>Scenario Date</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { scenarios.map((scenario) => (
                <TableRow key={scenario.jobId}>
                  <TableCell>
                    <Link to={`/range-estimation/scenario/${scenario.jobId}`}>
                      { (scenario.inputs && scenario.inputs.scenarioName) || `Scenario ${scenario.jobId}`}
                    </Link>
                  </TableCell>
                  <TableCell>{ formatDate(parseISO(scenario.submitDate), 'MM/dd/yyyy') }</TableCell>
                  <TableCell className="capitalize">{scenario.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      }
    </>
  );
};

export default SavedScenarios;
