import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBan,
  faCheckCircle,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import Offlinebolt from '@material-ui/icons/OfflineBolt';
import { Link } from 'react-router-dom';
import BatteryIcon from '../../../Shared/customIcons';
import { Map } from './Map';
import './Today.scss';

const hiddenVehicleSMN = 'BES CM0904 EV104B';
export const Today = ({ busData }) => {
  const immediate = [];
  const service3 = [];
  const service7 = [];
  const info = [];
  let color = '#4caf50';
  busData.alerts.forEach(alert => {
    switch (alert.severityId) {
      case 1:
        immediate.push(alert);
        break;
      case 2:
        service3.push(alert);
        break;
      case 3:
        service7.push(alert);
        break;
      case 4:
        info.push(alert);
        break;
    }
  });
  if (immediate.length > 0) {
    color = '#e53935';
  } else if (service3.length > 0) {
    color = '#ff7043';
  } else if (service7.length > 0) {
    color = '#ffb300';
  } else if (info.length > 0) {
    color = '#00bcd4';
  }
  console.log(busData);
  return (
    <div className='today-container'>
      <div className='left-container'>
        <div className='status-list'>
          <div>
            <span>
              {`${Math.round(busData.chargeState)}% `}
              <BatteryIcon chargedPercentage={busData.chargeState} />
            </span>

            <div className='bus-status-key'>STATE OF CHARGE</div>
          </div>

          <div>
            <span>{`${Math.round(busData.distRemainMile)} mi`}</span>
            <span className='bus-status-key'>REMAINING RANGE</span>
          </div>

          {busData.chargeConnection === '2' ||
          busData.chargeConnection === '4' ||
          busData.chargeConnection === '7' ||
          busData.chargeConnection === '9' ? (
            <div>
              <FontAwesomeIcon icon={faCheckCircle} className='check-circle' />
              <span className='bus-status-key'>PLUGGED IN</span>
            </div>
          ) : (
            <div>
              <FontAwesomeIcon icon={faBan} className='ban-circle' />
              <span className='bus-status-key'>NOT PLUGGED-IN</span>
            </div>
          )}

          <div>
            {busData.kWhpermi !== undefined
              ? busData.kWhpermi.toFixed(2)
              : 'N/A'}
            <span className='bus-status-key'>AVERAGE kWh/mi</span>
          </div>

          {/* <div>
            {busData.healthState === 'N/A' || busData.healthState <= 70 ? (
              <FontAwesomeIcon icon={faTimesCircle} className='ban-circle' />
            ) : (
              <FontAwesomeIcon icon={faCheckCircle} className='check-circle' />
            )}
            <span className='bus-status-key'>BATTERY HEALTH</span>
          </div>*/}
        </div>

        <Card className='bus-active-alert-card'>
          <CardContent className='bus-active-alerts-content'>
            <div className='bus-active-alerts-header'>
              <span>Active Alerts</span>
            </div>
            <div className='bus-active-alerts-content-wrapper'>
              {immediate.length > 0 && (
                <>
                  <div className='immediate-header'>
                    <div className='fault-priority'>IMMEDIATE</div>
                    <div className='immediate-count'>{immediate.length}</div>
                  </div>
                  {immediate.map((data, index) => (
                    <div
                      key={index.toString()}
                      className='immediate-description'
                    >
                      <Offlinebolt className='offline-bolt' />
                      <Link
                        to={`/faults-and-alerts/alerts/${data.faultID}?esn=${data.engineSerialNumber}`}
                        className='description'
                      >
                        {data.description}
                      </Link>
                    </div>
                  ))}
                </>
              )}
              {service3.length > 0 && (
                <>
                  <div className='service3-header'>
                    <div className='fault-priority'>SERVICE WITHIN 3 DAYS</div>
                    <div className='service3-count'>{service3.length}</div>
                  </div>
                  {service3.map((data, index) => (
                    <div
                      key={index.toString()}
                      className='service3-description'
                    >
                      <Offlinebolt className='offline-bolt' />
                      <Link
                        to={`/faults-and-alerts/alerts/${data.faultID}?esn=${data.engineSerialNumber}`}
                        className='description'
                      >
                        {data.description}
                      </Link>
                    </div>
                  ))}
                </>
              )}
              {service7.length > 0 && (
                <>
                  <div className='service7-header'>
                    <div className='fault-priority'>SERVICE WITHIN 7 DAYS</div>
                    <div className='service7-count'>{service7.length}</div>
                  </div>
                  {service7.map((data, index) => (
                    <div
                      key={index.toString()}
                      className='service7-description'
                    >
                      <Offlinebolt className='offline-bolt' />
                      <Link
                        to={`/faults-and-alerts/alerts/${data.faultID}?esn=${data.engineSerialNumber}`}
                        className='description'
                      >
                        {data.description}
                      </Link>
                    </div>
                  ))}
                </>
              )}
              {info.length > 0 && (
                <div className='pm-container'>
                  <div className='pm-header'>
                    <div className='fault-priority'>
                      NEXT PLANNED MAINTENANCE
                    </div>
                    <div className='pm-count'>{info.length}</div>
                  </div>
                  {info.map((data, index) => (
                    <div key={index.toString()} className='pm-description'>
                      <Offlinebolt className='offline-bolt' />
                      <Link
                        to={`/faults-and-alerts/alerts/${data.faultID}?esn=${data.engineSerialNumber}`}
                        className='description'
                      >
                        {data.description}
                      </Link>
                    </div>
                  ))}
                </div>
              )}
              {busData.alerts.length === 0 && (
                <div className='no-alerts'>No active alerts</div>
              )}
            </div>
          </CardContent>
        </Card>
      </div>

      <div className='right-container'>
        <Map
          currentLocation={[busData.latitude, busData.longitude]}
          color={color}
          busName={busData.customerEquipId}
          busData={busData}
        />
      </div>
    </div>
  );
};
