import React from 'react';
import PropTypes from 'prop-types';

export const TrimProgress = ({ progress }) => {
  if (progress.length === 0) {
    return null;
  }

  return (
    <div>Progress...</div>
  )
};

TrimProgress.propTypes = {
  progress: PropTypes.array.isRequired
}
