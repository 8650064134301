const STATUSKeywordMap = new Map();

// status that map to waitingApproval
STATUSKeywordMap.set('waitingapproval', 'waitingApproval');

// status that map to approved
STATUSKeywordMap.set('approved', 'approved');
STATUSKeywordMap.set('reapproved', 'approved');

// status that map to approved downloading
STATUSKeywordMap.set('pendingsend', 'downloading');
STATUSKeywordMap.set('sendingotapaused', 'downloading');
STATUSKeywordMap.set('sendingota', 'downloading');
STATUSKeywordMap.set('bufferingpaused', 'downloading');
STATUSKeywordMap.set('buffering', 'downloading');
STATUSKeywordMap.set('calrcvd', 'downloading');

// status that map to downloaded
STATUSKeywordMap.set('pendingapply', 'downloaded');

// status that map to installing
STATUSKeywordMap.set('applying', 'installing');
STATUSKeywordMap.set('startapplying', 'installing');

// status that map to upToDate
STATUSKeywordMap.set('applysucceeded', 'upToDate');
STATUSKeywordMap.set('revisionchanged', 'upToDate');

// status that map to failed
STATUSKeywordMap.set('sendfailed', 'downloadingFailed');
STATUSKeywordMap.set('sendingotafailed', 'downloadingFailed');
STATUSKeywordMap.set('bufferingfailed', 'downloadingFailed');
STATUSKeywordMap.set('applyfailed', 'installingFailed');
STATUSKeywordMap.set('authfailed', 'downloadingFailed');

export default STATUSKeywordMap;
