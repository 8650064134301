const BusDefaultData = {
  chargeConnection: {
    S: 'N/A'
  },
  chargeReachedFlag: {
    S: 'N/A'
  },
  chargeState: {
    S: 'N/A'
  },
  customerEquipId: {
    S: ''
  },
  customerName: {
    S: 'N/A'
  },
  distRemainKm: {
    S: 'N/A'
  },
  distRemainMile: {
    S: 'N/A'
  },
  equipVin: {
    S: ''
  },
  esn: {
    S: ''
  },
  serviceModelName: {
    S: ''
  },
  latitude: { S: 35.759575 },

  longitude: { S: -79.019302 }
};
export { BusDefaultData };

export const APPNAME = 'Cummins';
export const LOGINBUTTONTEXT = 'Login here';
export const SIGNUPBUTTONTEXT = 'Create an Account';
export const HAVEACCOUNTTEXT = 'Already have an Account?';
export const NEWUSERTEXT = 'New User? Create an Account';