import React from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import chargerImage from '../../../assets/images/charger.png';
import './Charger.css';

const PortCard = props => {

  const {
    connectivity,
    connectorTypes,
    defaultPorts,
    ports,
    portCount,
    portMaxAmps,
    onPortChange
  } = props;

  return (
    <Card
      className='charger-info-form_card charger-info-form_port'
      variant='outlined'
    >
      <div className='charger-image-container'>
        <img className='charger-image' src={chargerImage} />
      </div>
      <div className='charger-info-form_port_info'>
        <div className='charger-info-form_port_info-field'>
          <div className='charger-info-form_port_info-title'>CONNECTION</div>
          <div className='charger-info-form_port_info-value'>{connectivity}</div>
        </div>
        <div className='charger-info-form_port_info-field'>
          <div className='charger-info-form_port_info-title'>PORTS</div>
          <div className='charger-info-form_port_info-value'>{portCount}</div>
        </div>
        <div className='charger-info-form_port_max-container'>
          {portMaxAmps.map((amps, i) => (
            <div key={i} className='charger-info-form_port_max'>
              <span className='charger-info-form_port_max-title'>Port {i + 1}: </span>
              <span className='charger-info-form_port_max-value'>{amps} A</span>
              <span className='charger-info-form_port_max-label'>Max</span>
            </div>
          ))}
        </div>
        {ports.map((port, i) => (
          <div key={i} className='charger-info-form_group'>
            <InputLabel
              className='charger-info-form_required-label'
              htmlFor={`charger_form_port${i}`}
              disableAnimation
            >
              Port {i + 1}
            </InputLabel>
            <Select
              id={`charger_form_port${i}`}
              name={`port1${i}`}
              variant='outlined'
              fullWidth={true}
              value={port.value === '' ? defaultPorts[i] : port.value}
              error={port.error !== ''}
              onChange={e => onPortChange(i, e.target.value)}
            >
              {connectorTypes.map(connectorType => (
                <MenuItem
                  key={connectorType}
                  value={connectorType}
                >
                  {connectorType}
                </MenuItem>
              ))}
            </Select>
            {port.error !== '' && <FormHelperText error>This field is required.</FormHelperText>}
          </div>
        ))}
      </div>
    </Card>
  )
};

const ChargerForm = props => {

  const {
    state,
    onShowManualRegistration,
    onFieldChange,
    onPortChange
  } = props;

  const {
    name,
    manufacturer,
    model,
    description,
    ports
  } = state;

  const manufacturers = ['Siemens', 'Cummins'];
  const models = ['VersiCharge Ultra 175', 'VersiCharge Ultra 180'];
  const portMaxAmps = ['25', '30'];
  const defaultPorts = ['CSS1', 'CSS2'];
  const connectivity = 'WIFI';
  const portCount = 2;
  const connectorTypes = [
    'J1772',
    'CSS1',
    'CSS2',
    'CHADEMO'
  ];

  return (
    <form
      className='charger-info-form'
      id="scroll-dialog-description"
    >
      <Card
        className='charger-info-form_card'
        variant='outlined'
      >
        <h3 className='charger-info-form_title'>Tell us about your charger</h3>
        <div className='charger-info-form_group'>
          <InputLabel
            className='charger-info-form_required-label'
            htmlFor='charger_form_name'
          >
            Charger Name
          </InputLabel>
          <TextField
            fullWidth={true}
            id='charger_form_name'
            name='name'
            placeholder='Name you use to reference this charger'
            variant='outlined'
            value={name.value}
            error={name.error !== ''}
            onChange={onFieldChange}
          />
          {name.error !== '' && <FormHelperText error>This field is required.</FormHelperText>}
        </div>
        <div className='charger-info-form_group'>
          <InputLabel
            className='charger-info-form_required-label'
            htmlFor='charger_form_manufacturer'
          >
            Manufacturer Name
          </InputLabel>
          <Select
            fullWidth={true}
            id='charger_form_manufacturer'
            name='manufacturer'
            variant='outlined'
            value={manufacturer.value}
            error={manufacturer.error !== ''}
            onChange={onFieldChange}
          >
            {manufacturers.map(manufacturer => (
              <MenuItem key={manufacturer} value={manufacturer}>{manufacturer}</MenuItem>
            ))}
          </Select>
          {manufacturer.error !== '' && <FormHelperText error>This field is required.</FormHelperText>}
        </div>
        {manufacturer.value !== '' && (
          <div className='charger-info-form_group'>
            <InputLabel
              className='charger-info-form_required-label'
              htmlFor='charger_form_model'
            >
              Model
            </InputLabel>
            <Select
              fullWidth={true}
              id='charger_form_model'
              name='model'
              variant='outlined'
              value={model.value}
              error={model.error !== ''}
              onChange={onFieldChange}
            >
              {models.map(model => (
                <MenuItem key={model} value={model}>{model}</MenuItem>
              ))}
            </Select>
            {model.error !== '' && <FormHelperText error>This field is required.</FormHelperText>}
          </div>
        )}
        { model.value === '' && (
          <Button
            className='charger-info-form_show-manual-btn'
            fullWidth
            variant='contained'
            disableElevation
            onClick={onShowManualRegistration}
          >
            Not in the list?
          </Button>
        )}
        {model.value !== '' && (
          <div className='charger-info-form_group'>
            <InputLabel
              className='charger-info-form_label'
              htmlFor='charger_form_description'
            >
              Charger Description
            </InputLabel>
            <TextField
              fullWidth={true}
              id='charger_form_description'
              name='description'
              placeholder='Name you use to reference this charger'
              variant='outlined'
              multiline={true}
              value={description.value}
              onChange={onFieldChange}
            />
          </div>
        )}
      </Card>
      {model.value !== '' && (
        <PortCard
          connectivity={connectivity}
          connectorTypes={connectorTypes}
          portCount={portCount}
          portMaxAmps={portMaxAmps}
          defaultPorts={defaultPorts}
          ports={ports}
          onPortChange={onPortChange}
        />
      )}
    </form>
  );
};

export default ChargerForm;