import React, { Fragment } from 'react';
import { diffTime } from '../../../../utils/differenceInTime';

import {
  HoverWheelBatteryIcon,
  EvMapPin,
  CloseWheelIcon
} from '../../customIcons';

const getChargingState = busInfo => {
  if (
    busInfo.chargeConnection &&
    (busInfo.chargeConnection.S === '4' || busInfo.chargeConnection.S === '9')
  ) {
    return 'Charging';
  }
  if (
    busInfo.chargeConnection &&
    (busInfo.chargeConnection.S === '2' || busInfo.chargeConnection.S === '7')
  ) {
    return 'Plugged In \n Not charging';
  }
  return 'Not Plugged In';
};

const HoverWheelView = ({
  isActive,
  pinWheelRef,
  busInfo,
  battery,
  averageKMDisplay,
  stateColor,
  onMarkerClick,
  customName,
  showBatteryState,
  height,
  width,
  hideClose,
  onClickClose,
  history
}) => (
  <div
    id='pin-point'
    className={`pin-point ${isActive ? ' is-active' : ''}`}
    ref={pinWheelRef}
  >
    <div className='pin-wheel'>
      <ul>
        <li>
          <div className='segment bg-battery'>
            <div className='segment-content'>
              <div className='center-align'>
                <div className='lg-content'>
                  {busInfo.chargeState && busInfo.chargeState.S !== 'N/A'
                    ? `${Math.round(
                        busInfo.chargeState && busInfo.chargeState.S
                      )}%`
                    : 'N/A'}
                </div>
                <div className='icon-battery'>
                  <HoverWheelBatteryIcon
                    batteryfill='rgb(0,0,0)'
                    width='50px'
                    height='100%'
                    fillColor='black'
                    batterywidth={battery}
                  />
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className='segment bg-range'>
            <div className='segment-content'>
              <div className='center-align'>
                <div className='lg-content bold-text'>
                  {busInfo.distRemainMile && busInfo.distRemainMile.S === 'N/A'
                    ? 'N/A'
                    : `${parseInt(
                        busInfo.distRemainMile && busInfo.distRemainMile.S
                      )} mi`}
                </div>
                <div className='sm-content'>
                  Remaining <br /> Range
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className='segment bg-plugged'>
            <div className='segment-content'>
              <div className='center-align'>
                <div className='sm-content'>{getChargingState(busInfo)}</div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className='segment bg-sync'>
            <div className='segment-content'>
              <div className='center-align'>
                <div className='sm-content'>Last Sync</div>
                {diffTime(
                  busInfo.msgTimestamp && busInfo.msgTimestamp.S
                ).toUpperCase() && <div className='dot-icon red ' />}
                {!diffTime(
                  busInfo.msgTimestamp && busInfo.msgTimestamp.S
                ).toUpperCase() &&
                  diffTime(
                    busInfo.msgTimestamp && busInfo.msgTimestamp.S
                  ).toUpperCase()}
                <div
                  className={`lg-content ${
                    diffTime(
                      busInfo.msgTimestamp && busInfo.msgTimestamp.S
                    ).toUpperCase()
                      ? 'text-red'
                      : ''
                  }`}
                >
                  {diffTime(
                    busInfo.msgTimestamp && busInfo.msgTimestamp.S
                  ).toUpperCase()}
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className='segment bg-avg'>
            <div className='segment-content'>
              <div className='center-align'>
                <div className='sm-avg sm-content'>
                  AVERAGE <br /> kWh / mi
                </div>
                <div className='lg-content '>{averageKMDisplay}</div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className='segment bg-cust'>
            <div className='segment-content'>
              <div className='center-align'>
                <div className='sm-avg sm-content'>
                  Last Known Location <br/>
                </div>
                {busInfo.oldLocation !== undefined ? (
                <div className='lg-content '>{diffTime(busInfo.lastLocUpdate.S)}</div>
              ) : (
                <div className='lg-content '>{diffTime(busInfo.msgTimestamp.S)}</div>
              )}
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className='segment bg-eqp'>
            <div className='segment-content'/>
          </div>
        </li>
        <li>
          <div className='segment bg-esn'>
            <div className='segment-content'/>
          </div>
        </li>
      </ul>
    </div>
      <div
        id='map-pin'
        className={`map-pin ${stateColor}-pin ${isActive ? ' is-active' : ''}`}
        onClick={() => onMarkerClick()}
        role='link'
        tabIndex='0'
      >
        {customName && history.location.pathname === '/bus-active-faults' ? (
          <div className='pin-label'>
            <span style={{ fontSize: 10, fontWeight: 0 }}>{customName}</span>
          </div>
        ) : (
          <Fragment>
            {showBatteryState && (
              <div
                className={`pin-label ${isActive ? 'isActive-pin-label' : ''}`}
                onClick={() => onMarkerClick(busInfo)}
                role='link'
                tabIndex='0'
              >
                <span>
                  {busInfo.customerEquipId && busInfo.customerEquipId.S}
                </span>
                {!isActive ? (
                  <div style={{ paddingLeft: '6px' }}>
                    <HoverWheelBatteryIcon
                      batterywidth={battery}
                      batteryfill='rgb(255,255,255)'
                      width='24px'
                      height='100%'
                      fillColor='white'
                    />
                  </div>
                ) : null}
              </div>
            )}
          </Fragment>
        )}
        <EvMapPin stateColor={stateColor} height={height} width={width} />
      </div>
    {!hideClose && (
      <a onClick={e => onClickClose(e)} href='#top' className='close-wheel'>
        <CloseWheelIcon />
      </a>
    )}
  </div>
);

export default HoverWheelView;
