import React, { useState, useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import WarningIcon from '@material-ui/icons/Warning';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';
import HistoryIcon from '@material-ui/icons/History';
import { CircularProgress } from '@material-ui/core';
import './AlertCard.css';
import { formatDateTimeToLocal } from 'utils/dateUtils';
import api from 'utils/api';
import { AuthContext } from 'components/Contexts/AuthContext';
import { BusContext } from 'components/Contexts/BusContext';
import useBusPath from 'hooks/useBusPath';
import { logEvent } from 'utils/analyticsTracking';
import { Map } from './Map';


export const AlertCard = ({ alertInfo }) => {

  const [showDialog, setShowDialog] = useState(false);
  const [comments, setComments] = useState('');
  const [loading, setLoading] = useState(false);
  const authData = useContext(AuthContext);
  const busData = useContext(BusContext);
  let color;
  let message;
  switch (alertInfo.severity) {
    case 'Stop Now':
      message = 'Immediate';
      color = '#e53935';
      break;
    case 'Service Now':
      message = 'Service Within 3 Days';
      color = '#ff7043';
      break;
    case 'Service Soon':
      message = 'Service Within 7 Days';
      color = '#ffb300';
      break;
    case 'Information':
      message = 'Next Planned Maintenance';
      color = '#00bcd4';
      break;
  }

  const handleOpenAcknowledgement = () => {
    setShowDialog(true);
    logEvent({
      action: 'openDialog',
      category: 'Acknowledge Fault',
      label: 'acknowledge'
    })
  };

  const handleCancelAcknowledgement = () => {
    setShowDialog(false);
    logEvent({
      action: 'click',
      category: 'Acknowledge Fault',
      label: 'cancel'
    });
  };

  const handleSubmitAcknowledgement = () => {
    setShowDialog(false);
    setLoading(true);
    const payload = {
      ackComment: comments,
      faultID: alertInfo.faultID,
      fmi: alertInfo.fmi,
      spn: alertInfo.spn,
      dateOfOccurence: alertInfo.dateOfOccurence,
      engineSerialNumber: alertInfo.engineSerialNumber,
      serviceModelName: alertInfo.serviceModelName,
      customerFK: authData.customerList,
      userId: authData.userID,
      firstName: authData.givenName,
      lastName: authData.familyName,
      emailAddress: authData.username
    };
    api.acknowledgeFault.post(payload).then(() => {
      busData.getAllData();
      setLoading(false);
    });
    const ackLabel = (comments) ? 'submitWithComment' : 'submit';
    logEvent({
      action: 'click',
      category: 'Acknowledge Fault',
      label: ackLabel
    });
    

    let t1=new Date();
    let t2=new Date(alertInfo.dateOfOccurence);
    t2.setHours(t2.getHours()-4);
    var delta = Math.abs(t1 - t2) / 1000;
    var days = Math.floor(delta / 86400);
    delta -= days * 86400;
    var hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;
    var minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;
    var seconds = delta % 60;

    var timestring=`${days} days, ${hours} hours, ${minutes} minutes, and ${seconds} seconds between Fault Acknowledgement And Fault Occurrence.`;
    
    api.metaCx.addCommment({
      userId: authData.userID,
      eventKey: 'faultalert_acknowledged',
      source: 'external',
      eventDate: Date.now(),
      companyId: authData.customerList,
      busId: alertInfo.engineSerialNumber,
      busName: alertInfo.busID,
      companyName: '',
      alertTimeStamp: alertInfo.dateOfOccurence,
      timeUntilFaultAcknowledged: timestring      
      }).then((response)=>{
      if (response?.status !== 204)
      {
          console.log(`metaCx did not return 204 for faultalert_acknowledged`);
      }
      });


  };

  const busPath = useBusPath()

  return loading ? (
    <div className='page-loader'>
      <CircularProgress />
    </div>
  ) : (
    <>
      <div className='container1'>
        <header className='header1'>
          <div className='title1'>{alertInfo.description}</div>
          <div className='severity1' style={{ backgroundColor: color }}>
            <i className='fas fa-exclamation-triangle' />
            {` ${message}`}
          </div>
        </header>
        <main className='main1'>
          <section className='mapSection1'>
            <div className='banner1' style={{ backgroundColor: color }}>
              Trigger location
            </div>
            <Map
              lat={alertInfo.latitude}
              lng={alertInfo.longitude}
              color={color}
            />
          </section>
          <section className='infoSection1'>
            <div className='id1'>
              <OfflineBoltIcon className='bolt-icon' />
              <Link to={`${busPath}/${alertInfo.engineSerialNumber}/today`}>
                {alertInfo.busID}
              </Link>
            </div>
            <div className='fault1'>
              <WarningIcon className='warning-icon' />
              {`Fault ${alertInfo.faultID} . SPN ${alertInfo.spn} . FMI ${alertInfo.fmi}`}
            </div>
            <div className='time1'>
              <div>
              <HistoryIcon />
              Fault Occurrence Date:&nbsp;
              <b>{formatDateTimeToLocal(alertInfo.dateOfOccurence)}</b>
              </div>
              <div className='faultOccurenceCount'>Fault Occurrence Count : <b>{alertInfo.faultOccurrenceCount}</b></div>
            </div>
            <div className='ack1'>
              <button
                type='button'
                className='blueBtn1'
                onClick={handleOpenAcknowledgement}
              >
                Acknowledge
              </button>
              <Link
                to={`/faults-and-alerts/alerts/${alertInfo.faultID}?esn=${alertInfo.engineSerialNumber}`}
              >
                <button type='button' className='whiteBtn1'>
                  Learn More
                </button>
              </Link>
            </div>
          </section>
        </main>
      </div>
      <Dialog
        onClose={() => setShowDialog(false)}
        open={showDialog}
        maxWidth='xl'
      >
        <div className='approve-dialog'>
          <div className='select-bar'>
            <h3>Acknowledge Alert</h3>
            <div>
              <Button variant='contained' onClick={handleCancelAcknowledgement}>
                Cancel
              </Button>
              <Button
                variant='contained'
                color='primary'
                onClick={handleSubmitAcknowledgement}
              >
                Submit
              </Button>
            </div>
          </div>
          <div className='comments'>
            <textarea
              cols='115'
              rows='10'
              placeholder='Your comments (Optional)'
              value={comments}
              onChange={e => setComments(e.target.value)}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};
