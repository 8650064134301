import React, { useContext, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { AlertCard } from './AlertCard/AlertCard';
import { BusContext } from '../../../Contexts/BusContext';
import HappyBus from '../../../../assets/Icons/happy-bus.svg';

import './Alerts.css';

export const Alerts = () => {
  const context = useContext(BusContext);
  const alerts = context.activeAlertData;
  const [cardIndex, setCardIndex] = useState(10);

  function loadCards() {
    const cards = [];
    for (let i = 0; i < cardIndex && i < alerts.length; i += 1) {
      cards.push(<AlertCard key={i} alertInfo={alerts[i]} />);
    }
    return cards;
  }
  return alerts ? (
    <>
      {alerts.length > 0 ? (
        <div className='container-alerts'>
          {loadCards()}
          {cardIndex < alerts.length && (
            <button
              className='loadBtn'
              type='button'
              onClick={() => {
                setCardIndex(cardIndex + 5);
              }}
            >
              Click here to Load more...
            </button>
          )}
        </div>
      ) : (
          <div className='no-active'>
            <img src={HappyBus} alt='bus icon' height='130' />
            You have no alerts active for your fleet
          </div>
      )}
    </>
  ) : (
    <div className='page-loader'>
      <CircularProgress />
    </div>
  );
};
