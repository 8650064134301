import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import './BusList.scss';
import { Link } from 'react-router-dom';
import BatteryIcon from '../../../Shared/customIcons';
import IconElectricity from '../../../../assets/Icons/icon_electricity.svg';
import IconNoLocation from '../../../../assets/Icons/no_location.svg';
import CustomMap from '../../../Shared/Map/CustomMap';
import { diffTime } from '../../../../utils/differenceInTime';

const hiddenVehicleSMN = 'BES CM0904 EV104B';
const BusListView = ({
  equipmentStatus,
  getCount,
  engineIssues,
  inActiveIssues,
  onMarkerSecondClick
}) => (
  <div className='listTableComp'>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Bus</TableCell>
          <TableCell>Details</TableCell>
          <TableCell />
          <TableCell />
          <TableCell />
          <TableCell align='center'>Open Issues</TableCell>
          <TableCell>Last Sync</TableCell>
        </TableRow>
      </TableHead>
      <TableBody id='test-id'>
        {equipmentStatus.map((value, index) => (
          <TableRow key={index.toString()}>
            <TableCell>
              <img
                className='ev'
                src={IconElectricity}
                alt='Icon-Electricity'
              />
              <Link
                to={`/buses/${value.esn.S}/today`}
                className='list-bus-name'
              >
                {value.customerEquipId.S}
              </Link>
            </TableCell>
            <TableCell>
              <span>
                <BatteryIcon chargedPercentage={value.chargeState.S} />
                <span className='padding-left-sm'>
                  {`${Math.round(value.chargeState.S)}%`}
                </span>
              </span>
            </TableCell>
            <TableCell>
              {value.chargeConnection.S === '2' ||
              value.chargeConnection.S === '4' ||
              value.chargeConnection.S === '9' ||
              value.chargeConnection.S === '7' ? (
                <div className='battery-status'>
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <span className='padding-left-sm'>Plugged In</span>
                </div>
              ) : (
                <div className='battery-status'>
                  <FontAwesomeIcon icon={faBan} />
                  <span className='padding-left-sm'>Not Plugged In</span>
                </div>
              )}
            </TableCell>
            {value.serviceModelName.S !== hiddenVehicleSMN ? (
              <TableCell>
                {value.chargeConnection.S === '4' ||
                value.chargeConnection.S === '9' ? (
                  <div className='battery-status'>
                    <FontAwesomeIcon icon={faCheckCircle} />
                    <span className='padding-left-sm'>Charging </span>
                  </div>
                ) : (
                  <div className='battery-status'>
                    <FontAwesomeIcon icon={faBan} />
                    <span className='padding-left-sm'>Not Charging</span>
                  </div>
                )}
              </TableCell>
            ) : (
              <TableCell />
            )}
            {parseFloat(value.longitude.S) === 0.0 &&
            parseFloat(value.latitude.S) === 0.0 ? (
              <TableCell>
                <Tooltip title='No Location Data Provided'>
                  <img
                    className='ev'
                    src={IconNoLocation}
                    alt='Icon-NoLocation'
                  />
                </Tooltip>
              </TableCell>
            ) : (
              <TableCell />
            )}
            <TableCell align='center'>
              {getCount(value.customerEquipId.S)}
            </TableCell>
            <TableCell>{diffTime(value.msgTimestamp.S)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    <CustomMap
      googleMapURL='//maps.googleapis.com/maps/api/js?key=AIzaSyAqTh9Qo2F017zQAp1aILMOWwmUbVne9h0'
      loadingElement={<div style={{ height: `400%` }} />}
      containerElement={<div style={{ height: `600px`, padding: '10px' }} />}
      mapElement={<div style={{ height: `100%` }} />}
      data={equipmentStatus}
      engineIssues={engineIssues}
      inActiveIssues={inActiveIssues}
      fullscreenControl
      onMarkerSecondClick={onMarkerSecondClick}
      showBatteryState
      showResetButton
      showAlertDataSeverity
    />
  </div>
);

export default BusListView;
