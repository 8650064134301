import React from 'react';
import ReactDOM from 'react-dom';
import GoogleMapReact from 'google-map-react';

export const Map = ({ currentLocation, color, busName, busData }) => {
  const defaultProps = {
    center: {
      lat: 0,
      lng: 0
    },
    zoom: 10
  };

  const MapOptions = {
    // https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
  };

  const handleApiLoaded = (map, maps) => {
    // Initial fitBounds
    const bounds = new maps.LatLngBounds();
    bounds.extend(new maps.LatLng(currentLocation[0], currentLocation[1]));

    // Don't zoom in too far on only one marker
    if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
      const extendPoint1 = new maps.LatLng(
        bounds.getNorthEast().lat() + 0.01,
        bounds.getNorthEast().lng() + 0.01
      );
      const extendPoint2 = new maps.LatLng(
        bounds.getNorthEast().lat() - 0.01,
        bounds.getNorthEast().lng() - 0.01
      );
      bounds.extend(extendPoint1);
      bounds.extend(extendPoint2);
    }

    map.fitBounds(bounds);
    // Click button to fitBounds
    const controlButtonDiv = document.createElement('div');
    ReactDOM.render(
      <ResetBtn refit={() => map.fitBounds(bounds)} />,
      controlButtonDiv
    );
    map.controls[maps.ControlPosition.RIGHT_BOTTOM].push(controlButtonDiv);
  };

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '300px', width: '100%', margin: '0 auto' }}>
      {parseFloat(currentLocation[0]) == 0.0 &&
      parseFloat(currentLocation[1]) == 0.0 ? (
        <span>Bus cannot be displayed on map due to unknown location</span>
      ) : (
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyAqTh9Qo2F017zQAp1aILMOWwmUbVne9h0' }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          options={MapOptions}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        >
          <PinDrop
            label={busName}
            lat={parseFloat(currentLocation[0])}
            lng={parseFloat(currentLocation[1])}
            color={color}
            busData={busData}
          />
        </GoogleMapReact>
      )}
    </div>
  );
};

function ResetBtn(props) {
  return (
    <button
      type='button'
      onClick={() => props.refit()}
      style={{
        padding: '9px',
        marginRight: '10px',
        marginBottom: '-8px',
        border: 'none',
        backgroundColor: 'white',
        borderRadius: '2px',
        outline: 'none'
      }}
    >
      <svg width='22px' height='22px' viewBox='0 0 22 22'>
        <g
          id='Icons'
          stroke='none'
          strokeWidth='1'
          fill='none'
          fillRule='evenodd'
        >
          <g
            id='Icons/Zoom--Reset--Glass--Solid'
            transform='translate(-1.000000, -1.000000)'
            fill='#666'
          >
            <path
              d='M12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 C18.0751322,1 23,5.92486775 23,12 C23,13.8386864 23,17.1720197 23,22 C23,22.5522809 22.5522879,22.999993 22.000007,23 C17.2851506,23 13.9518149,23 12,23 Z M7.76625,7.7625 L6.00375,6 L6.00375,11.25 L11.25375,11.25 L8.83875,8.835 C9.64875,8.0175 10.75875,7.5 12.00375,7.5 C14.48625,7.5 16.50375,9.5175 16.50375,12 C16.50375,14.4825 14.48625,16.5 12.00375,16.5 C10.04625,16.5 8.38125,15.2475 7.76625,13.5 L6.20625,13.5 C6.87375,16.0875 9.20625,18 12.00375,18 C15.31875,18 17.99625,15.315 17.99625,12 C17.99625,8.685 15.31875,6 12.00375,6 C10.34625,6 8.85375,6.675 7.76625,7.7625 Z'
              id='Icon'
            />
          </g>
        </g>
      </svg>
    </button>
  );
}

function PinDrop({ color, label, busData }) {
  return (
    <div>
      <span
        style={{
          position: 'absolute',
          border: '1px solid #fff',
          backgroundColor: '#000',
          color: '#fff',
          whiteSpace: 'nowrap',
          padding: '3px',
          borderRadius: '4px',
          top: '-50px',
          transform: 'translate(-50%)'
        }}
      >
        {label}
      </span>
      <svg
        width='30px'
        height='30px'
        viewBox='0 0 46 46'
        style={{ position: 'relative', transform: 'translate(-50%, -100%)' }}
      >
        <g
          id='Map-Component-Pin-Base-Maintenance'
          serifid='Map/Component/Pin/Base/Maintenance'
          transform='matrix(1,0,0,1,3,2)'
        >
          <g id='Shadow'>
            <path
              id='path-1'
              d='M8.066,36.051C3.171,32.405 0,26.573 0,20C0,8.954 8.954,0 20,0C31.046,0 40,8.954 40,20C40,26.792 36.615,32.793 31.439,36.408L20,46.25L8.057,36.051L8.066,36.051Z'
            />
            <path
              id='path-11'
              serifid='path-1'
              d='M8.066,36.051C3.171,32.405 0,26.573 0,20C0,8.954 8.954,0 20,0C31.046,0 40,8.954 40,20C40,26.792 36.615,32.793 31.439,36.408L20,46.25L8.057,36.051L8.066,36.051Z'
              style={{ fill: 'white' }}
            />
          </g>
          <path
            style={{ fill: color }}
            id='Color'
            d='M8.066,36.051C3.171,32.405 0,26.573 0,20C0,8.954 8.954,0 20,0C31.046,0 40,8.954 40,20C40,26.792 36.615,32.793 31.439,36.408L20,46.25L8.057,36.051L8.066,36.051Z'
          />
          <path
            id='Border'
            d='M8.066,36.051C3.171,32.405 0,26.573 0,20C0,8.954 8.954,0 20,0C31.046,0 40,8.954 40,20C40,26.792 36.615,32.793 31.439,36.408L20,46.25L8.057,36.051L8.066,36.051ZM9.259,34.446L9.251,34.446L20,43.625L30.295,34.767C34.953,31.514 38,26.113 38,20C38,10.059 29.941,2 20,2C10.059,2 2,10.059 2,20C2,25.916 4.854,31.165 9.259,34.446Z'
            style={{ fill: 'white' }}
          />
          <g id='Icon' transform='matrix(1,0,0,1,10,10)'>
            <path
              d='M10,1C5.029,1 1,5.03 1,10C1,14.97 5.029,19 10,19C14.971,19 19,14.971 19,10C19,5.029 14.971,1 10,1ZM13.301,9.469C11.962,11.788 10.622,14.107 9.282,16.426C9.278,16.435 9.272,16.443 9.266,16.452C9.175,16.623 9.068,16.786 8.841,16.703C8.603,16.617 8.659,16.408 8.69,16.231C8.794,15.647 8.907,15.065 9.016,14.484C9.21,13.46 9.404,12.437 9.596,11.413C9.612,11.326 9.617,11.236 9.632,11.111C9.506,11.106 9.399,11.095 9.292,11.096C8.536,11.099 7.779,11.104 7.022,11.107C6.577,11.108 6.471,10.922 6.697,10.531C8.036,8.212 9.376,5.893 10.716,3.575C10.72,3.566 10.726,3.557 10.732,3.548C10.822,3.377 10.93,3.215 11.157,3.297C11.395,3.383 11.338,3.592 11.307,3.77C11.204,4.353 11.091,4.935 10.982,5.516C10.788,6.54 10.594,7.563 10.402,8.587C10.385,8.674 10.381,8.764 10.366,8.889C10.492,8.895 10.599,8.905 10.705,8.904C11.462,8.902 12.219,8.896 12.976,8.894C13.422,8.894 13.527,9.078 13.301,9.469Z'
              style={{ fill: 'white' }}
            />
          </g>
        </g>
      </svg>
    </div>
  );
}
