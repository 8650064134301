/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-globals */
import React from 'react';
import Paper from '@material-ui/core/Paper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateRangePicker } from 'react-date-range';
import ReactToPrint from 'react-to-print';
import Button from '@material-ui/core/Button';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { addDays } from 'date-fns';
import { useAuth } from 'components/Contexts/AuthContext';
import { logEvent } from 'utils/analyticsTracking';
import { Reports } from '../Reports/Reports';

export const PrintView = ({
  start,
  end,
  updateState,
  menuItemFlag,
  saveDateInputBoxRef,
  showPicker,
  selectionRange,
  handleSelect,
  newD,
  min,
  max,
  totalkwhpermile,
  isSingleBus
}) => {
  const { setLastAction } = useAuth();
  const logAction = (e) => {
    e.stopPropagation();
    setLastAction();
  };
  const logPrint = () => {
    logEvent({
      action: 'click',
      category: 'Analytics',
      label: 'Print'
    });
  };
  return (
    <div className='analytics-page'>
      <Paper>
        <div className='date-picker-bar'>
          <fieldset className='date-picker-fieldset'>
            <legend className='date-picker-text'>Date / Range</legend>
            <div
              className='ev-input-box'
              ref={r => saveDateInputBoxRef(r)}
              onClick={() =>
                updateState({
                  showPicker: !showPicker,
                  menuItemFlag: !menuItemFlag
                })
              }
              role='menuitem'
              tabIndex='0'
            >
              <div className='ev-calendar'>
                <i className='far fa-calendar'/>
              </div>
              <span className='ev-dates-display'>
                {start.toDateString()} - {end.toDateString()}
              </span>

              <span className='ev-arrow-display'>
                <FontAwesomeIcon icon={menuItemFlag ? faAngleUp : faAngleDown}/>
              </span>
              <span
                onClick={logAction}
                role='menuitem'
                tabIndex='0'
                id='datePickerConatainer'
                name='calendarPanel'
                style={{
                  position: 'absolute',
                  top: '40px',
                  left: '0',
                  display: showPicker ? 'block' : 'none',
                  outline: 'none'
                }}
              >
                <DateRangePicker
                  ranges={[selectionRange]}
                  onChange={handleSelect}
                  months={2}
                  direction='horizontal'
                  staticRanges={newD}
                  inputRanges={[]}
                  maxDate={addDays(new Date(), -1)}
                  minDate={addDays(new Date(), -31)}
                />
              </span>
            </div>
          </fieldset>

          <div>
            <ReactToPrint
              onBeforePrint={logPrint}
              trigger={() => (
                <Button variant='contained' style={{ boxShadow: 'none' }}>
                  PRINT
                </Button>
              )}
              content={() => self.componentRef}
            />
          </div>
        </div>

        <Reports
          ref={el => (self.componentRef = el)}
          max={max}
          min={min}
          totalkwhpermile={totalkwhpermile}
          isSingleBus={isSingleBus}
        />
      </Paper>
    </div>
  );
};
