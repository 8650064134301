import React from 'react';
import PropTypes from 'prop-types';
import { useFeatureFlags } from 'components/Contexts/FeatureFlagContext';

/**
 * Component to check for the existence, and truthiness, of a named feature
 * flag. If the flag exists, and evaluates as true, the component will render
 * its children. Otherwise, it will return null. To put it another way, the
 * default is to render null UNLESS a flag with the feature name exists AND
 * evaluates to true. This way, errors such as misnaming a flag or deleting
 * info from the config will result in sensitive features NOT being rendered.
 *
 * @param children Child elements to render if the feature is enabled
 * @param featureName the name of the feature
 * @returns {null|*}
 */
const FeatureFlagComponent = ({ children, featureName }) => {
  const flags = useFeatureFlags();
  if (flags[featureName]) {
    return (
      <>
        { children }
      </>
    );
  }
  return null;
};

FeatureFlagComponent.propTypes = {
  children: PropTypes.element.isRequired,
  featureName: PropTypes.string.isRequired
};

export const FeatureFlag = FeatureFlagComponent;
