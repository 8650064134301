import React from 'react';
import { GoogleMap } from 'react-google-maps';

import CustomControl from './CustomControl';
import { ResetIcon } from '../../customIcons';

const CustomMapView = ({
    saveMapRef,
    mapRef,
    center,
    zoom,
    streetViewControl,
    fullscreenControl,
    onIdle,
    customflag,
    handleResetZoom,
    onCustomControl,
    resetColor,
    markers,
    isBoundSet
}) => (
    <GoogleMap
        ref={r => {
            saveMapRef(r);
        }}
        center={center}
        defaultZoom={1}
        zoom={zoom}
        options={{
            scrollwheel: false,
            minZoom: 1,
            maxZoom: 20,
            mapTypeControl: false,
            streetViewControl: streetViewControl || false,
            fullscreenControl: fullscreenControl || false,
            styles: [
                {
                    featureType: 'poi',
                    elementType: 'labels',
                    stylers: [
                        {
                            visibility: 'off'
                        }
                    ]
                },
                {
                    featureType: 'transit',
                    elementType: 'labels',
                    stylers: [
                        {
                            visibility: 'off'
                        }
                    ]
                },
                {
                    featureType: 'poi.business',
                    elementType: 'labels',
                    stylers: [
                        {
                            visibility: 'off'
                        }
                    ]
                },
                {
                    featureType: 'poi.medical',
                    elementType: 'labels',
                    stylers: [
                        {
                            visibility: 'off'
                        }
                    ]
                },
                {
                    featureType: 'poi.park',
                    stylers: [
                        {
                            visibility: 'off'
                        }
                    ]
                },
                {
                    featureType: 'poi.place_of_worship',
                    stylers: [
                        {
                            visibility: 'off'
                        }
                    ]
                },
                {
                    featureType: 'transit.line',
                    elementType: 'geometry.fill',
                    stylers: [
                        {
                            color: '#706f81'
                        },
                        {
                            saturation: '-18'
                        },
                        {
                            gamma: '2.27'
                        },
                        {
                            visibility: 'on'
                        },
                        {
                            weight: '2.36'
                        }
                    ]
                },
                {
                    featureType: 'transit.line',
                    elementType: 'labels.icon',
                    stylers: [
                        {
                            hue: '#ff0000'
                        },
                        {
                            lightness: '-30'
                        },
                        {
                            visibility: 'on'
                        }
                    ]
                },
                {
                    featureType: 'poi.school',
                    elementType: 'labels',
                    stylers: [
                        {
                            visibility: 'off'
                        }
                    ]
                }
            ]
        }}
        onIdle={onIdle}
    >
        {customflag && isBoundSet ? (
            <CustomControl
                mapHolderRef={mapRef}
                controlPosition={
                    window.google.maps.ControlPosition.RIGHT_BOTTOM
                }
            >
                <button
                    id='ev-reset'
                    type='button'
                    className='ev-reset-button '
                    onClick={handleResetZoom}
                >
                    <em
                        className='ev-reset-clr'
                        onMouseOver={() => onCustomControl('#000')}
                        onMouseLeave={() => onCustomControl('#666')}
                    >
                        {' '}
                        <ResetIcon fillcolor={resetColor} />{' '}
                    </em>
                </button>
                <div className='button-divider' />
            </CustomControl>
        ) : (
            ''
        )}
        {markers}
    </GoogleMap>
);

export default CustomMapView;
