import { useReducer } from 'react';

const initialState = {
  name: {
    value: '',
    error: ''
  },
  manufacturer: {
    value: '',
    error: ''
  },
  model: {
    value: '',
    error: ''
  },
  description: {
    value: '',
    error: ''
  },
  ports: [
    {
      value: '',
      error: ''
    },
    {
      value: '',
      error: ''
    }
  ]
};

const getFieldUpdate = (state, data) => {

  const { field, value } = data;

  switch (field) {
    case 'manufacturer': {
      const { name } = state;
      return {
        ...initialState,
        name,
        manufacturer: {
          value,
          error: ''
        }
      };
    }
    case 'model': {
      return {
        ...state,
        'ports': initialState.ports,
        model: {
          value,
          error: ''
        }
      };
    }
    case 'port': {
      const ports = [...state.ports];
      const { portNumber } = data;
      ports[portNumber] = {
        value,
        error: ''
      };
      return {
        ...state,
        ports
      };
    }
    default:
      return {
        ...state,
        [field]: {
          value,
          error: ''
        }
      };
  }
}

const getFieldErrorUpdate = (state, data) => {

  const { fields, portNumbers = [] } = data;

  const newFieldState = fields.reduce(
    (fieldState, fieldName) => ({
      ...fieldState,
      [fieldName]: {
        ...state[fieldName],
        error: 'required'
      }
    }),
    state
  );

  const { ports } = state
  const newPorts = [...ports];
  portNumbers.forEach(portNumber => {
    newPorts[portNumber] = {
      ...ports[portNumber],
      error: 'required'
    };
  });

  return {
    ...newFieldState,
    ports: newPorts
  };
}

const reducer = (state, action) => {

  const { data } = action;

  switch (action.type) {
    case 'add field error':
      return getFieldErrorUpdate(state, data);
    case 'clear required field errors': {
      const fields = ['name', 'manufacturer', 'model'];
      return fields.reduce(
        (prevState , field) => ({
          ...prevState ,
          [field]: {
            ...prevState [field],
            error: ''
          }
        }),
        state
      );
    }
    case 'update field':
      return getFieldUpdate(state, data);
    case 'update manual registration field': {
      const { field, value } = data;
      return {
        ...state,
        [field]: {
          value,
          error: ''
        }
      };
    }
    default:
      return state;
  }
}

const useChargerForm = () => {

  const [
    state,
    dispatch
  ] = useReducer(reducer, initialState);

  return {
    state,
    dispatch
  };
}

export default useChargerForm;