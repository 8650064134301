import React from 'react';
import { Card, CardContent, Avatar } from '@material-ui/core';
import { formatDateTimeToLocal } from 'utils/dateUtils';

const CommnentView = ({ comment }) => {
  const userName = `${
    comment.firstName ? comment.firstName.replace(/['"]+/g, '') : ''
  } ${comment.lastName ? comment.lastName.replace(/['"]+/g, '') : ''}`;

  return (
    <Card className='comment-card'>
      <CardContent className='comment-card-content'>
        <div className='comment-item-details-container'>
          <Avatar alt={userName} className='comment-avatar' />
          <div className='comment-item-details'>
            <span className='proximanova-text comment-item-text'>
              {comment.uComment ? comment.uComment.replace(/['"]+/g, '') : ''}
            </span>
            <span className='proximanova-bold commnet-item-name-date'>
              {userName.trim().length ? userName : 'Test Name'} /{' '}
              {formatDateTimeToLocal(comment.commentDate)}
            </span>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default CommnentView;
