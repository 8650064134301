import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Auth from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';

import { fromUnixTime, differenceInMilliseconds } from 'date-fns';

import API from 'utils/api';
import { TimeoutPrompt } from './TimeoutPrompt/TimeoutPrompt';
import { LandingScreen } from '../../LandingScreen';

/**
 * Sets API urls, tokens, and Cognito configuration
 *
 * @param settings
 * @returns {Promise<void>}
 */
export const ConfigureOAuth = settings =>
  new Promise(resolve => {
    let doneInitializing = true;

    // Wait to resolve the promise until amplify is fully initialized
    // if handling redirect from oAuth authorization_code grant event order is:
    // codeFlow, signIn, configured
    // when loading the app using cached credentials only configured event fires
    Hub.listen(
      'auth',
      ({ payload }) => {
        // console.log('authEvent', payload);
        switch (payload.event) {
          case 'codeFlow':
            doneInitializing = false;
            break;
          case 'signIn':
            // console.log('signIn:', payload.data);
            resolve();
            break;
          case 'configured':
            if (doneInitializing === true) {
              resolve();
            }
            break;
          case 'customOAuthState':
            // return to original route  after sign-in
            window.location.href = decodeURIComponent(payload.data);
            break;
        }
        if (payload.event === 'codeFlow') {
          doneInitializing = false;
        }
        if (doneInitializing && payload.event === 'configured') {
          resolve();
        }
      },
      'AuthContext'
    );

    Auth.configure(settings);
  });

export const AuthContext = React.createContext();

export const AuthContextProvider = ({ providerName, children }) => {
  const [EVUser, setEVUser] = useState(null);
  const [cognitoUser, setCognitoUser] = useState(null);
  const [showLandingScreen, setShowLandingScreen] = useState(false);
  const [promptForTimeout, setPromptForTimeout] = useState(false);
  const interactionTimeout = useRef(null);

  const handleSignOut = () => {
    Auth.signOut();
  };

  const setLastAction = () => {
    setPromptForTimeout(false);
    if (interactionTimeout.current) {
      clearInterval(interactionTimeout.current);
    }
    interactionTimeout.current = setTimeout(() => {
      setPromptForTimeout(true); // Show the timeout prompt after 30 minutes of inactivity
    }, 30 * 60 * 1000); // 30 minutes in milliseconds
  };

  // handle initial user load
  useEffect(() => {
    Auth.currentSession()
      .then(session => {
        setCognitoUser(session);
      })
      .catch((e) => {
        console.error('Error accessing logged in user info.', e);
        setShowLandingScreen(true);
        // const route = (window.location.pathname+window.location.search).substr(1);
        // Auth.federatedSignIn({ provider: providerName, customState: route })}
      });
  }, [providerName]);

  // handle token refresh before expiration of access token
  useEffect(() => {
    if (!cognitoUser) return;

    const { accessToken, refreshToken } = cognitoUser;

    /*
    // time authenticated - note, refresh token is configured to expire at 30 days
    console.log(`Authenticated: ${fromUnixTime(accessToken.payload.auth_time)}`);
    // issued at
    console.log(`Issued At: ${fromUnixTime(accessToken.payload.iat)}`);
    // expiration
    console.log(`Expiration: ${fromUnixTime(accessToken.payload.exp)}`);
    */

    // default access token expiration is 60 minutes
    // setting to expire 5 minute prior for any possible offset issues
    const accessTokenExpiration =
      fromUnixTime(accessToken.payload.exp) - 300000;

    setTimeout(() => {
      Auth.currentAuthenticatedUser()
        .then(userSession => {
          userSession.refreshSession(refreshToken, (error, refreshedUser) => {
            setCognitoUser(refreshedUser);
          });
        })
        .catch(e => {
          console.error('Error obtaining new access token', e);
        });
    }, differenceInMilliseconds(accessTokenExpiration, Date.now()));
  }, [cognitoUser]);

  // update Context state when user changes
  useEffect(() => {
    if (!cognitoUser) return;

    const { idToken, accessToken } = cognitoUser;

    const role = idToken.payload['custom:Role'];
    if (role !== 'EV User') {
      handleSignOut();
    }

    setEVUser({
      username: idToken.payload.email,
      familyName: idToken.payload.family_name,
      givenName: idToken.payload.given_name,
      userID: idToken.payload['custom:salesforceId'],
      customerList: idToken.payload['custom:customerList'],
      role: idToken.payload['custom:Role']
    });

    API.setToken(accessToken.jwtToken);
  }, [cognitoUser]);

  if (showLandingScreen) {
    return <LandingScreen providerName={providerName} />;
  }
  
	return (
    <AuthContext.Provider value={{ ...EVUser, setLastAction, signOut: handleSignOut }}>
      { EVUser ? children : null }
      { promptForTimeout ? <TimeoutPrompt handleSignOut={handleSignOut} setLastAction={setLastAction} /> : null }
	  </AuthContext.Provider>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.element,
  providerName: PropTypes.string.isRequired
};

export const useAuth = () => useContext(AuthContext);
