import React, {useEffect} from 'react';
import { useAuth } from 'components/Contexts/AuthContext';
import PropTypes from 'prop-types';
import api from 'utils/api';
import {
  AllInclusive,
  OfflineBolt,
  Schedule
} from '@material-ui/icons';
import './SimulatorResults.module.css';

export const SimulatorResults = ({results,data}) => {
  const { userID, customerList } = useAuth();
  // send event to metaCx 
  useEffect(() => {
    api.metaCx.addCommment({
      userId: userID,
      eventKey: 'rangeestimationresults_view',
      source: 'external',
      eventDate: Date.now(),
      companyName: '',
      companyId: customerList,
      busId: data.job.inputs.vehicle.vehicleId,
      busName: data.job.inputs.vehicle.designation
    }).then((response)=>{
        if (response?.status !== 204)
        {
          console.log('metaCx did not return 204.');
        }
    });
  },[data.jobId]);
  return(
    <div>
      <div className="resultRow">
        <div className="resultBox">
          <OfflineBolt/>
          <h4 id="kwhmi">{results.kilowattHoursPerMile} kWh/mi</h4>
          <span>Electricity Consumption</span>
        </div>
        <div className="resultBox">
          <AllInclusive/>
          <h4 id="laps">{results.laps}</h4>
          <span>Number of Laps</span>
        </div>
        <div className="resultBox">
          <i className="fas fa-road fa-2x"/>
          <h4 id="range">{results.range} mi</h4>
          <span>Estimated Range</span>
          </div>
          <div className="resultBox">
            <Schedule/>
            <h4 id="duration">{results.duration}</h4>
            <span>Trip Duration</span>
          </div>
      </div>
    </div>
  )
}

SimulatorResults.propTypes = {
  results: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};
