import React, { Component } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import './electrificationReports.scss';
import { ReportsView } from './ReportsView';

export class Reports extends Component {
  render() {
    const { min, max, totalkwhpermile, isSingleBus } = this.props;

    // distance was showing ALL decimal places. Round it to 2 decimals or
    // set it to 0 if falsy (null, undefined, 0, etc). NOTE: Number()
    // conversion needed because API will return the value as a String
    // when looking at a single bus.
    const roundedData = totalkwhpermile
      ? totalkwhpermile.map(item => ({
          ...item,
          distance: item.distance ? Number(item.distance).toFixed(2) : 0
        }))
      : null;

    return (
      <ReportsView
        totalkwhpermile={roundedData}
        max={max}
        min={min}
        isSingleBus={isSingleBus}
      />
    );
  }
}