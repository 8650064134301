import React, { Fragment, useContext, useState, useEffect } from 'react';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink, useRouteMatch, useHistory } from 'react-router-dom';
import {
  faBus,
  faChartPie,
  faDownload,
  faExclamationTriangle,
  faRoute,
  //faInfoCircle
} from '@fortawesome/free-solid-svg-icons';
import { FeatureFlag } from 'components/Shared/FeatureFlag/FeatureFlag';
import { useFeatureFlags } from 'components/Contexts/FeatureFlagContext';
import { BusContext } from '../../Contexts/BusContext';
import HeaderApp from './HeaderApp';
import CumminsLogo from '../../../assets/Icons/logo_cummins.svg';
import PreventechLogo from '../../../assets/Icons/PrevenTech_EV_OneLine_White.png'
import './Sidebar.scss';

const AssetList = () => {

    const isBuses = !!useRouteMatch('/assets/buses');
    const isChargers = !!useRouteMatch('/assets/chargers');
    const isAssetRoute = isBuses || isChargers;
    const selectedClass = isAssetRoute ? 'asset-list_header-selected' : '';

    const history = useHistory()

    const [
        showAssetList,
        setShowAssetList
    ] = useState(isAssetRoute);

    useEffect(() => {
        setShowAssetList(isAssetRoute)
    }, [isAssetRoute]);

    return (
        <div>
            <div
                className={`asset-list_header ${selectedClass}`}
                onClick={() => {
                    if(showAssetList) {
                        setShowAssetList(false)
                    }
                    else if(isAssetRoute) {
                        setShowAssetList(true)
                    }
                    else {
                        history.push('/assets/buses')
                    }
                }}
            >
                <FontAwesomeIcon
                    className='nav-icon'
                    icon={faBus}
                />
                <span className='asset-list_title'>ASSETS</span>
                <div>
                    {showAssetList ? <ExpandLess /> : <ExpandMore />}
                </div>
            </div>
            { showAssetList && (
                <div className='asset-list_container'>
                    <NavLink
                        className='asset-list_item'
                        activeClassName='asset-list_item-selected'
                        to='/assets/buses'
                    >
                        Buses
                    </NavLink>
                    <NavLink
                        className='asset-list_item'
                        activeClassName='asset-list_item-selected'
                        to='/assets/chargers'
                    >
                        Chargers
                    </NavLink>
                </div>
            )}
        </div>
    );
}

export const Sidebar = () => {
    const busData = useContext(BusContext);
    const [isOpen, setIsOpen] = useState(false);
    const flags = useFeatureFlags();

    const year = (new Date()).getFullYear();

    const navLinks = () => (
        <div>
            <Toolbar className='logoBar'>
                <div className='imageContainer'>
                    <img src={CumminsLogo} alt='cumminsLogo' />
                    <img src={PreventechLogo} alt='preventech ev logo' width="155px"/>
                </div>
            </Toolbar>

            <Divider />
            {flags.chargeManagement ? <AssetList /> : (
                <NavLink
                    onClick={() => {
                        setIsOpen(false);
                    }}
                    className='nav-link'
                    activeClassName='nav-selected'
                    to='/buses'
                >
                    <FontAwesomeIcon className='nav-icon' icon={faBus} />
                    BUSES
                </NavLink>
            )}
            <NavLink
                onClick={() => {
                    setIsOpen(false);
                }}
                className='nav-link'
                activeClassName='nav-selected'
                to='/faults-and-alerts'
            >
                <FontAwesomeIcon
                    className='nav-icon'
                    icon={faExclamationTriangle}
                />
                FAULTS AND ALERTS
                {busData.totalIssueCount > 0 && (
                    <span className='ev-alertsCount'>
                        {busData.totalIssueCount}
                    </span>
                )}
            </NavLink>
            <NavLink
                onClick={() => {
                    setIsOpen(false);
                }}
                className='nav-link'
                activeClassName='nav-selected'
                to='/analytics'
            >
                <FontAwesomeIcon className='nav-icon' icon={faChartPie} />
                ANALYTICS
            </NavLink>
            <FeatureFlag featureName="scenarioSimulator">
                <NavLink
                    onClick={() => {
                        setIsOpen(false);
                    }}
                    className='nav-link'
                    activeClassName='nav-selected'
                    to='/range-estimation'
                >
                    <FontAwesomeIcon className='nav-icon' icon={faRoute} />
                    RANGE ESTIMATION
                </NavLink>
            </FeatureFlag>
            <NavLink
                onClick={() => {
                    setIsOpen(false);
                }}
                className='nav-link'
                activeClassName='nav-selected'
                to='/software-updates/updates'
            >
                <FontAwesomeIcon className='nav-icon' icon={faDownload} />
                SOFTWARE UPDATES
            </NavLink>
            {/* <div className="container">
                 <div className="row">
                     <div className="col">
                         <div className="icon-row">
                             <span className="text">  
                             <FontAwesomeIcon className='nav-icon' icon={faInfoCircle} />Vehicle data updates every 30 minutes. Please refer to 'Last sync time' to know when data was last reported from your vehicle.</span>
                         </div>
                     </div>
                 </div>
             </div> */}
        </div>
    );
    
    const contact = (termsAndConditions) => (
      <div className="contact-us">
        <section className="contact-links">
          <div>Questions?</div>
          <div className="support-mail">
            <a href="mailto:AcceleraDigital.Support@cummins.com">AcceleraDigital.Support@cummins.com</a>
            </div>
        </section>
        <section>
          <div className="sub-section">
            <a href="http://www.cummins.com/website-privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
            { termsAndConditions ? <a href="/TermsAndConditions.pdf" target="_blank" rel="noopener noreferrer">Terms and Conditions</a> : null }
          </div>
          <div className="sub-section">
            <div>&copy; {year} Cummins Box 3005</div>
            <div>Columbus, IN 47202-3005 USA</div>
          </div>
        </section>
      </div>
    );

    return (
        <Fragment>
            <HeaderApp
                drawerToggle={() => {
                    setIsOpen(!isOpen);
                }}
            />
            <Hidden mdUp>
                <Drawer
                    variant='temporary'
                    anchor='left'
                    open={isOpen}
                    onClose={() => setIsOpen(false)}
                    classes={{ paper: 'drawer-paper' }}
                >
                  <div className="nav-container">
                    {navLinks()}
                    {contact(flags.termsAndConditions)}
                  </div>
                </Drawer>
            </Hidden>
            <Hidden smDown>
                <Drawer
                    variant='permanent'
                    open
                    classes={{ paper: 'drawer-paper' }}
                >
                  <div className="nav-container">
                    {navLinks()}
                    {contact(flags.termsAndConditions)}
                  </div>
                </Drawer>
            </Hidden>
        </Fragment>
    );
};
