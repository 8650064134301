import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { validateForm } from 'utils/FormValidation'

export const useForm = ({ initialValues, validations, onSubmit }) => {
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});

    const formRendered = useRef(true);

    useEffect(() => {
        if (formRendered.current) {
            setValues(initialValues);
            setErrors({});
        }
        formRendered.current = false;
    }, []);

    /**
     * Handle a field change.
     */
    const handleChange = (event) => {
        event.persist();
        setValues({
           ...values,
           [event.target.name]: event.target.value
        })
    };

    /**
     * Validate the current values. If there are no errors, call the onSubmit function.
     *
     * @param event the form submission event
     */
    const handleSubmit = (event) => {
        event.preventDefault();
        const fieldErrors = validateForm(values, validations);
        if (fieldErrors.hasErrors) {
            setErrors(fieldErrors);
        } else {
            setErrors({});
            onSubmit(values);
        }
    };

    return {
        errors,
        handleChange,
        handleSubmit,
        values
    }
};

useForm.propTypes = {
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    validations: PropTypes.object
};

useForm.defaultProps = {
    initialValues: {},
    validations: {}
};
