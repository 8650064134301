import PropTypes from 'prop-types';
import React from 'react';

import './FieldErrors.module.css';

/**
 * Labels for field errors.
 * Encapsulates the null-check for the errors and the i18n lookups. This makes the form code cleaner, instead of
 * constantly duplicating this simple bit of code for every field.
 *
 * The `errors` prop is an array of objects. Each object must contain a `key` property (which is the i18n key to the
 * translation.json files), and may optionally include `values` property, which is a key/value object to use for
 * interpolation of the variables in the i18n string.
 */
const FieldErrorsComponent = ( { errors } ) => {
  if (errors) {
    return errors.map( (error, index) => (
      <span className="errorMessage" key={index}>
        {error}
      </span>
    ));
  }
  return '';
};

FieldErrorsComponent.propTypes = {
  errors: PropTypes.array
};

export const FieldErrors = FieldErrorsComponent;
