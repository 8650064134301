import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import PropTypes from 'prop-types';

export const TimeoutPrompt = ({ handleSignOut, setLastAction }) => {
  const [minutes, setMinutes] = useState(5);
  const timeout = useRef(null);

  const clearTimeout = () => {
    if (timeout.current) {
      clearInterval(timeout.current);
    }
  }

  useEffect(() => {
    clearTimeout();
    timeout.current = setTimeout(() => {
      handleSignOut();
    }, 5 * 60 * 1000);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (minutes > 0) {
        setMinutes(minutes - 1);
      }
    }, 60 * 1000);
  }, [minutes]);

  const handleContinue = () => {
    clearTimeout();
    setLastAction();
  }

  return (
    <Dialog open>
      <DialogTitle>
        Inactivity Warning
      </DialogTitle>
      <DialogContent>
        <p>Due to inactivity, your session will close in <strong>{minutes}</strong> {minutes > 1 ? 'minutes' : 'minute'}.</p>
        <p>Please click "Continue" to extend your session.</p>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={handleContinue}
          variant="contained"
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TimeoutPrompt.propTypes = {
  handleSignOut: PropTypes.func.isRequired,
  setLastAction: PropTypes.func.isRequired
};
