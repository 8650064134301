import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';

import './SelectableVehicle.module.css';

export const SelectableVehicle = ({ enabled, isSelected, onSelect, vehicle }) => {

  const checkSelected = (e) => {
    onSelect(vehicle.esn, e.target.checked);
  };

  return (
    <div className={isSelected ? 'vehicleSelection isSelected' : 'vehicleSelection'}>
      <Checkbox
        color="primary"
        disabled={!enabled}
        id={`check_${vehicle.esn}`}
        onChange={checkSelected}
      />
      <label htmlFor={`check_${vehicle.esn}`} className="vehicleDetails">
        <div><strong>{ vehicle.customerEquipId }</strong></div>
        <div className="vehicleESN">{ vehicle.esn }</div>
      </label>
    </div>
  );
};

SelectableVehicle.propTypes = {
  enabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  vehicle: PropTypes.object.isRequired
};
