import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import EmailIcon from '@material-ui/icons/Email';

const DeliveryMethods = props => (
  <div className='delivery-methods-container'>
    <div className='delivery-methods-header'>Delivery Methods</div>
    <div className='delivery-methods-subtext'> You will receive an e-mail each time an 'Immediate' severity fault goes active, along with on daily summary report e-mail each morning for all other faults. The daily summary report includes all non-immediate faults that went active the day prior for the entire fleet</div>
    <div className='delivery-methods-content'>
      <div>
        <Checkbox
          checked={props.isChecked}
          className='active-check-box'
          onChange={() => props.onChange({ receiveEmail: !props.isChecked })}
        />
        <span className='email-alert-text'>Do Not Receive Email Alerts</span>
      </div>
      <div className='email-input-container'>
        <fieldset className='email-fieldset'>
          <legend className='email-legend'>Email</legend>
          <div className='email-input-wrapper'>
            <EmailIcon />
            <TextField
              placeholder='Type your email here...'
              className='email-input'
              fullWidth
              value={props.userEmail}
              onChange={e => props.onChangeEmail(e)}
            />
          </div>
        </fieldset>
      </div>
    </div>
  </div>
);
export default DeliveryMethods;
