// print-container
import { createStaticRanges } from 'react-date-range';
import { addDays } from 'date-fns';

export const calendarData = createStaticRanges([
  {
    label: 'Last 7 days',
    range: () => ({
      startDate: addDays(new Date(), -7),
      endDate: addDays(new Date(), -1)
    })
  },
  {
    label: 'Last 15 days',
    range: () => ({
      startDate: addDays(new Date(), -16),
      endDate: addDays(new Date(), -1)
    })
  },
  {
    label: 'Last 30 days',
    range: () => ({
      startDate: addDays(new Date(), -31),
      endDate: addDays(new Date(), -1)
    })
  }
]);
