import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { ElectricityIcon } from '../../../Shared/customIcons';
import './RenderAlerts.scss';

const RenderAlertsView = ({ count, type, faults, styledClass, countClass }) => (
  <>
    {count > 0 && (
      <Fragment>
        <div className='alerts-title'>
          <h4 className='alert-priority-title'>{type}</h4> &nbsp;
          <span className={countClass}>{count}</span>
        </div>
        <ul className='list-alerts'>
          {faults.map(
            (item, index) =>
              item.description &&
              !item.isAcknowledge && (
                <li key={index} className={styledClass}>
                  <Link
                    to={`/faults-and-alerts/alerts/${item.faultID}?esn=${item.engineSerialNumber}`}
                  >
                    <ElectricityIcon fillColor='white' />
                    <span className='bus-details'>
                      <span className='bus-name'>{item.equipmentId}</span>
                      <span className='bus-description'>
                        {item.description}
                      </span>
                    </span>
                  </Link>
                </li>
              )
          )}
        </ul>
      </Fragment>
    )}
  </>
);

export default RenderAlertsView;
