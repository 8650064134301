import React, { Component } from 'react';
import { addDays, format } from 'date-fns';
import { calendarData } from 'utils/calendarData';
import API from 'utils/api';
import { PrintView } from './PrintView';

const newD = [...calendarData];

export class Print extends Component {
  state = {
    start: addDays(new Date(), -7),
    end: addDays(new Date(), -1),
    showPicker: false,
    max: 0,
    min: 0,
    totalkwhpermile: [],
    menuItemFlag: false
  };

  componentDidMount() {
    this.getData();
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleSelect = ranges => {
    const { startDate, endDate } = ranges.selection;
    this.setState(
      {
        start: startDate,
        end: endDate
      },
      () => {
        this.getData();
      }
    );
    if (JSON.stringify(startDate) !== JSON.stringify(endDate)) {
      this.setState({ showPicker: false, menuItemFlag: false });
    }
  };

  getData = async () => {
    const startDate = format(this.state.start, 'yyyy-MM-dd');
    const endDate = format(this.state.end, 'yyyy-MM-dd');
    let totalkwhpermile;

    if (this.props.isSingleBus) {
      const busPerformanceAnalytics = await API.performance.getBusPerformanceAnalytics(
        {
          engineSerialNumber: this.props.esn,
          periodStartDate: startDate,
          periodEndDate: endDate
        }
      );
      totalkwhpermile = busPerformanceAnalytics.performances;
    } else {
      totalkwhpermile = await API.totalKwhPerMile.get({
        startDate,
        endDate,
        fleetLevel: 1,
        custName: this.props.custName
      });
    }
    totalkwhpermile = totalkwhpermile.filter(item => item.distance != null);
    totalkwhpermile = totalkwhpermile.map(item => {
      if (item.kWhpermi) {
        item.kWhpermi = parseFloat(item.kWhpermi).toFixed(2);
      }
      return item;
    });
    const usefulDistance = totalkwhpermile.filter(item => item.distance >= 10);
    this.setState({
      totalkwhpermile,
      max: Math.max(...usefulDistance.map(b => b.kWhpermi)),
      min: Math.min(
        ...usefulDistance.filter(b => b.kWhpermi !== null).map(b => b.kWhpermi)
      )
    });
  };

  // handle click outside view
  handleClickOutside = event => {
    if (!this.dateInputBox.contains(event.target)) {
      this.setState({ showPicker: false, menuItemFlag: false });
    }
  };

  updateState = values => {
    this.setState(values);
  };

  saveDateInputBoxRef = value => {
    this.dateInputBox = value;
  };

  render() {
    const {
      start,
      end,
      menuItemFlag,
      showPicker,
      min,
      max,
      totalkwhpermile
    } = this.state;
    const selectionRange = {
      startDate: this.state.start,
      endDate: this.state.end,
      key: 'selection'
    };
    return (
      <PrintView
        start={start}
        end={end}
        updateState={this.updateState}
        menuItemFlag={menuItemFlag}
        showPicker={showPicker}
        saveDateInputBoxRef={this.saveDateInputBoxRef}
        selectionRange={selectionRange}
        handleSelect={this.handleSelect}
        newD={newD}
        self={this}
        min={min}
        max={max}
        totalkwhpermile={totalkwhpermile}
        isSingleBus={this.props.isSingleBus}
      />
    );
  }
}
