import React from 'react';
import {
  ResponsiveContainer,
  ComposedChart,
  Legend,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from 'recharts';
import { CustomizedLabel } from './CustomizedLabel';

export const ReportsView = ({ totalkwhpermile, max, min, isSingleBus }) => (
  <div className='ev-charts'>
    <div className='graph-container'>
      <span className='graph-title'>
        {isSingleBus ? 'Average Consumption' : 'Average Fleet Consumption'}
      </span>
      <ResponsiveContainer minWidth={860} height={300}>
        <ComposedChart
          data={
            totalkwhpermile &&
            totalkwhpermile.map(item => {
              if (item.distance < 10) {
                delete item.kWhpermi;
              }
              return item;
            })
          }
          syncId='fleet'
          margin={{ top: 30, right: 0, left: 0, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='date' padding={{ right: 40, left: 40 }} />
          <YAxis unit=' kWh/mi' width={100} padding={{ top: 40 }} />
          <YAxis width={100} yAxisId='right' orientation='right' />
          <Tooltip />
          <Legend />
          <Line
            name='kWh/mi'
            type='linear'
            dataKey='kWhpermi'
            stroke='#e040fb'
            isAnimationActive={false}
            connectNulls
            fill='#FFFFFF'
            strokeWidth={2}
            label={params =>
              parseFloat(params.value) === parseFloat(max) ||
              parseFloat(params.value) === parseFloat(min) ? (
                <CustomizedLabel {...params} max={max} min={min} />
              ) : (
                ''
              )
            }
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>

    <div className='graph-container'>
      <span className='graph-title'>
        {isSingleBus
          ? 'Total Consumption Over Distance Driven'
          : 'Total Fleet Consumption And Distance Driven'}
      </span>
      <ResponsiveContainer minWidth={860} height={300}>
        <ComposedChart
          data={totalkwhpermile}
          margin={{ top: 30, right: 0, left: 0, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='date' padding={{ right: 40, left: 40 }} />
          <YAxis yAxisId='left' width={100} padding={{ top: 40 }} unit=' kWh' />
          <YAxis width={100} yAxisId='right' orientation='right' unit=' mi' />
          <Tooltip />
          <Legend />
          <Bar
            yAxisId='right'
            dataKey='distance'
            name='Miles'
            barSize={50}
            fill='#40c4ff'
          />
          <Line
            yAxisId='left'
            type='linear'
            dataKey='kWh'
            stroke='#e040fb'
            connectNulls
            strokeWidth={2}
            fill='#FFFFFF'
            isAnimationActive={false}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  </div>
);
