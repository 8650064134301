import React from 'react';
import { OverlayView } from 'react-google-maps';
import useBusPath from 'hooks/useBusPath';
import HoverWheelContainer from './HoverWheel';
import { BusContext } from '../../Contexts/BusContext';
import { AuthContext } from '../../Contexts/AuthContext';

const getPixelPositionOffset = (width, height) => ({
  x: -(width / 2),
  y: -(height / 2)
});

const CustomMarker = ({
  position,
  onMarkerClick,
  onCloseIconClick,
  showWheel,
  isActive,
  busInfo,
  busDetails,
  engineIssues,
  hideClose,
  showTooltip,
  customName,
  showBatteryState,
  height,
  width,
  showCurrentLocationColor,
  history,
  showAlertDataSeverity
}) => {
  const busPath = useBusPath();

  return (
    <AuthContext.Consumer>
      {authContext => (
        <BusContext.Consumer>
          {busContext => (
            <OverlayView
              key={Math.random()}
              position={position}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              getPixelPositionOffset={getPixelPositionOffset}
            >
              <HoverWheelContainer
                onMarkerClick={onMarkerClick}
                closeIconClick={onCloseIconClick}
                isActive={showWheel || isActive}
                busInfo={busInfo}
                busDetails={busDetails}
                busPath={busPath}
                engineIssues={engineIssues}
                hideClose={hideClose}
                showTooltip={showTooltip}
                customName={customName}
                showBatteryState={showBatteryState}
                height={height}
                width={width}
                busContextData={busContext}
                authContext={authContext}
                showCurrentLocationColor={showCurrentLocationColor}
                history={history}
                showAlertDataSeverity={showAlertDataSeverity}
              />
            </OverlayView>
          )}
        </BusContext.Consumer>
      )}
    </AuthContext.Consumer>
  );
};

export default CustomMarker;
