const formatTimeDuration = (duration) => {
  const hours = Math.floor(duration);
  const minutes = Math.floor((duration - hours) * 60);
  return `${hours}h : ${minutes}m`;
};

export const mapResults = (resultRes) => {
  let results = null;
  if (resultRes && resultRes.status !== '404 NOT FOUND') {
    results = {
      kilowattHoursPerMile: Number(resultRes.values.consumption).toFixed(2),
      laps: resultRes.values.routeCycles,
      range: Number(resultRes.values.range).toFixed(2),
      duration: formatTimeDuration(resultRes.values.electricDur)
    }
  }
  return results;
};

export const mapRouteInfo = (routeRes) => {
  const routeData = {
    defaultRoute: '',
    hasRoutes: false,
    routes: []
  };
  if (routeRes && routeRes.length) {
    routeData.routes = routeRes.map((route) => ({ id: route, name: route }));
    routeData.defaultRoute = (routeData.routes && routeData.routes.length === 1) ? routeData.routes[0].id : '';
    routeData.hasRoutes = true;
  }
  return routeData;
};

export const mapSimulation = (simulationId, options) => {
  const { defaultRoute, defaultTire, defaultVehicle, simRes } = options;
  const defaults = {
    battery: 100, // Slider control, numeric
    finalBattery: 0,
    bus: defaultVehicle, // Select control
    driverExpertise: '1', // Control is a radio group, val treated as string
    ridership: '0.5', // Control is a radio group, val treated as string
    route: defaultRoute, // Select control
    temperature: 35, // Slider control, numeric
    tire: defaultTire, // Select control
    scenarioName: '' // Text input
  };

  let sim;
  if (simRes) {
    sim = {
      battery: simRes.inputs.batteryStateOfCharge,
      finalBattery: simRes.inputs.batteryFinalStateOfCharge || 0,
      bus: simRes.inputs.vehicle.vehicleId,
      driverExpertise: `${simRes.inputs.driverBehavior}`,
      jobId: simulationId,
      ridership: `${simRes.inputs.ridership}`,
      route: simRes.inputs.routeName,
      scenarioName: simRes.inputs.scenarioName || '',
      temperature: simRes.inputs.ambientTemp,
      tire: simRes.inputs.tire.tireId
    }
  }
  return sim || defaults;
};

export const mapTireInfo = (tireRes) => {
  const tireData = {
    defaultTire: '',
    mappedTires: {},
    tires: []
  };
  if (tireRes && tireRes.length) {
    tireRes.forEach((tire, index, tires) => {
      tireData.mappedTires[tire.tireId] = tire;
      tireData.tires.push({
        id: tire.tireId,
        name: `${tire.brand} ${tire.model}`
      });
      if (tires.length === 1 && index === 0) {
        tireData.defaultTire = tire.tireId;
      }
    });
  }
  return tireData;
};

export const mapVehicleInfo = (vehicleRes) => {
  const vehicleData = {
    defaultBus: '',
    mappedVehicles: {},
    vehicles: []
  };
  if (vehicleRes && vehicleRes.length) {
    vehicleRes.forEach((vehicle, index, vehicles) => {
      vehicleData.mappedVehicles[vehicle.vehicleId] = vehicle;
      vehicleData.vehicles.push({
        id: vehicle.vehicleId,
        name: vehicle.designation
      });
      if (vehicles.length === 1 && index === 0) {
        vehicleData.defaultBus = vehicle.vehicleId;
      }
    });
  }
  return vehicleData;
};
