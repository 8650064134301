import React, { useContext, useLayoutEffect } from 'react';
import { useAuth } from 'components/Contexts/AuthContext';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Card } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { useParams, useLocation, Link } from 'react-router-dom';
import useBusPath from 'hooks/useBusPath';
import { formatDateTimeToLocal } from 'utils/dateUtils';
import { diffTime } from 'utils/differenceInTime';
import api from 'utils/api';
import { ElectricityIcon } from '../../Shared/customIcons';
import AcknowledgeComments from '../../Shared/AcknowledgeComments';
import './FaultDetail.scss';
import { BusContext } from '../../Contexts/BusContext';

import { Map } from './Map';

export const FaultDetail = () => {
  let busData;
  let faultData;
  let notFound = false;
  const query = new URLSearchParams(useLocation().search);
  const esn = query.get('esn');
  const context = useContext(BusContext);
  const { faultID } = useParams();
  const { userID, customerList } = useAuth();
  let mostSevereActiveFault;

  

  try {
    faultData = context.faults.find(
      fault => fault.faultID === parseInt(faultID) && fault.esn === esn
    );
    mostSevereActiveFault = faultData;
    busData = faultData.busStatus;
    if (busData.alerts.length > 0) {
      let mostSevere = busData.alerts[0].severityId;
      let alertID = busData.alerts[0].faultID;
      busData.alerts.forEach(alert => {
        if (alert.severityId < mostSevere) {
          mostSevere = alert.severityId;
          alertID = alert.faultID;
        }
      });
      mostSevereActiveFault = context.faults.find(
        fault => fault.faultID === alertID
      );
    }

    // metaCx event
    api.metaCx.addCommment({
      userId: userID,
      eventKey: 'faultalert_viewed',
      source: 'external',
      eventDate: Date.now(),
      companyName: '',
      companyId: customerList,
      busId: mostSevereActiveFault.esn,
      busName: busData.customerEquipId,
      alerttimestamp: Date.parse(mostSevereActiveFault.dateOfOccurence)
    }).then((response)=>{
      if (response?.status !== 204)
      {
        console.log('metaCx did not return 204.');
      }
    });

  
    
  } catch (err) {
    notFound = true;
  }
  useLayoutEffect(() => {
    window.scroll(0, 0);
  });

  const busPath = useBusPath()

  return !context.faults ? (
    <div className='page-loader'>
      <CircularProgress />
    </div>
  ) : notFound || !busData ? (
    <div>page not found</div>
  ) : (
    <div className='selected-alerts-div'>
      <div>
        <Card>
          <div className='paper-selected'>
            <div className='selected-bus-name'>
              <ElectricityIcon className='electric-charge-icon' />
              <Link to={`${busPath}/${busData.esn}/today`}>
                &nbsp;
                {busData.customerEquipId}
              </Link>
            </div>
            <div className='selected-bus-details'>
              <span>
                SSN: <span>{busData.esn}</span>
              </span>
              <span>
                SMN: <span>{busData.serviceModelName}</span>
              </span>
              <span>
                VIN: <span>{busData.equipVin ? busData.equipVin : null}</span>
              </span>
              <span>
                Last data Sync: <span>{diffTime(busData.msgTimestamp)}</span>
              </span>
            </div>
          </div>
        </Card>
        <Card className='fault-desc'>
          <Grid container spacing={4}>
            <div className='fault-title'>
              <h3>{faultData.faultDescription}</h3>
              <span
                style={
                  faultData.faultStatus === 'true'
                    ? { backgroundColor: faultData.color }
                    : { backgroundColor: '#BDBDBD' }
                }
              >
                {faultData.severity}
              </span>
            </div>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <div className='fault-details'>
                {`Fault ${faultData.faultID} - SPN ${faultData.spn} - FMI ${faultData.fmi}`}
              </div>
              <div className='last-received'>
                <div className='sm-text-last proximanova-text '>
                  Fault Occurrence Date :&nbsp;
                  {/* <small className='proximanova-bold lg-text-time'> */}
                    {formatDateTimeToLocal(faultData.dateOfOccurence)}
                  {/* </small> */}
                </div>
              </div>
              <div className='sm-text-last proximanova-text '>
                Fault Occurrence Count :&nbsp;
                {/* <small className='proximanova-bold lg-text-time'> */}
                {faultData.faultOccurrenceCount}
                {/* </small> */}
              </div>
              <div className='suggestions'>
                <Divider />
                <div className='recommendation'>
                  <h3>Recommendation</h3>
                  <p>{faultData.faultRec || 'Not Available'}</p>
                </div>
                <Divider />
                <div className='root-cause'>
                  <h3>Potential cause</h3>
                  <p>{faultData.rootcause || 'Not Available'}</p>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Map
                triggerLocation={[faultData.latitude, faultData.longitude]}
                currentLocation={
                  faultData.faultStatus === 'true'
                    ? [busData.latitude, busData.longitude]
                    : false
                }
                color={faultData.color}
                mostSevereColor={mostSevereActiveFault.color}
              />
            </Grid>
          </Grid>
        </Card>
        <Card className='acknowledge-comments'>
          <AcknowledgeComments faultDetails={faultData} busDetails={busData} />
        </Card>
        <div />
      </div>
    </div>
  );
};
