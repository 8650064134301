import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withScriptjs, withGoogleMap } from 'react-google-maps';
import CustomMarker from '../CustomMarker';
import './CustomMap.scss';

import CustomMap from './CustomMap-View';

export class CustomControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flag: true
    };
    this._map = null;
  }

  addToMap(root) {
    if (this.state.flag) {
      const { mapHolderRef, controlPosition } = this.props;
      mapHolderRef.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.controls[
        controlPosition
      ].push(root);
      this.setState({ flag: false });
    }
  }

  render() {
    return (
      <div className='reset-btn-bottom' ref={this.addToMap.bind(this)}>
        {this.props.children}
      </div>
    );
  }
}

class CustomMapContainer extends Component {
  state = {
    zoom: this.props.zoom || 1,
    isBoundSet: false,
    activeIndex: -1,
    initialzoom: this.props.zoom || 1,
    lat: null,
    lng: null,
    resetColor: '#666'
  };

  componentDidMount() {
    this._isMounted = true;
    this.setState({
      center: this.getCenter()
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onMarkerClick = (item, index) => {
    const lat = parseFloat(item.latitude.S);
    const lng = parseFloat(item.longitude.S);
    if (this.state.activeIndex === index) {
      if (this.props.onMarkerSecondClick) this.props.onMarkerSecondClick(item);
    } else {
      this.setState({
        activeIndex: index,
        lat,
        lng,
        center: { lat, lng }
      });
    }
  };

  onCloseIconClick = () => {
    this.setState(prevState => ({
      activeIndex: -1,
      zoom: prevState.initialzoom
    }));
  };

  onIdle = () => {
    if (
      !this.state.isBoundSet &&
      !this.props.isStaticMap &&
      this._map &&
      this._isMounted
    ) {
      this._map.fitBounds(this._bounds);
      this.setState(
        {
          isBoundSet: true,
          zoom: this._map.getZoom() - 1,
          initialzoom: this._map.getZoom() - 1
        },
        () => {}
      );
    }
    if (!this.state.isBoundSet) {
      this.setState({
        isBoundSet: true
      });
    }
  };

  onCustomControl = color => {
    this.setState({ resetColor: color });
  };

  handleManually = (event, ...args) => {
    this._map.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED[event](
      ...args
    );
    this.setState({ center: this.getAllMarkerCenter() });
  };

  handleResetZoom = () => {
    this.handleManually('setZoom', this.state.initialzoom);
  };

  saveMapRef = r => {
    this._map = r;
  };

  getCenter = () => {
    if (!this.state.lat || !this.state.lng) {
      return this.getAllMarkerCenter();
    }
    return { lat: this.state.lat, lng: this.state.lng };
  };

  // center the map for all markers
  getAllMarkerCenter = () => {
    this._bounds = new window.google.maps.LatLngBounds();
    this.props.data.forEach(item => {
      if (
        parseFloat(item.longitude.S) === 0.0 &&
        parseFloat(item.latitude.S) === 0.0
      ) {
        // This block intentionally left empty
      } else {
        const position = new window.google.maps.LatLng(
          parseFloat(item && item.latitude && item.latitude.S),
          parseFloat(item && item.longitude && item.longitude.S)
        );
        this._bounds.extend(position);
      }
    });
    return this._bounds.getCenter();
  };

  render() {
    const { showWheel, history } = this.props;
    const { center } = this.state;
    if (!this.props.data || this.props.data.length === 0) return null;
    const markers =
      this.props.data &&
      this.props.data.map((item, index) => (
        <>
          {parseFloat(item.longitude.S) === 0.0 &&
          parseFloat(item.latitude.S) === 0.0 ? (
            <></>
          ) : (
            <CustomMarker
              key={`BUSMARKER_${index}`}
              onMarkerClick={() => {
                if (showWheel === false) return;
                this.onMarkerClick(item, index);
              }}
              onCloseIconClick={this.onCloseIconClick}
              position={{
                lat: parseFloat(item.latitude && item.latitude.S),
                lng: parseFloat(item.longitude && item.longitude.S)
              }}
              busInfo={item}
              busDetails={this.props.busDetails}
              isActive={this.state.activeIndex === index}
              customName={item.locationName}
              showWheel={showWheel || false}
              hideClose={this.props.hideClose || false}
              showBatteryState={this.props.showBatteryState || false}
              height={this.props.markerHeight}
              width={this.props.markerWidth}
              engineIssues={
                item.locationName !== 'Triggered' && this.props.engineIssues
              }
              showCurrentLocationColor={this.props.showCurrentLocationColor}
              history={history}
              showAlertDataSeverity={this.props.showAlertDataSeverity}
            />
          )}
        </>
      ));

    let customflag = false;
    if (this._map && this.props.showResetButton) {
      customflag = true;
    }
    return (
      <CustomMap
        saveMapRef={this.saveMapRef}
        mapRef={this._map}
        center={center}
        zoom={this.state.zoom}
        streetViewControl={this.props.streetViewControl}
        fullscreenControl={this.props.fullscreenControl}
        onIdle={this.onIdle}
        customflag={customflag}
        handleResetZoom={this.handleResetZoom}
        onCustomControl={this.onCustomControl}
        resetColor={this.state.resetColor}
        markers={markers}
        isBoundSet={this.state.isBoundSet}
        showCurrentLocationColor={this.props.showCurrentLocationColor}
      />
    );
  }
}
export default withScriptjs(withGoogleMap(withRouter(CustomMapContainer)));
