import React, { Component } from 'react';

export default class CustomControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flag: true
    };
  }

  addToMap(root) {
    if (this.state.flag) {
      const { mapHolderRef, controlPosition } = this.props;
      mapHolderRef.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.controls[
        controlPosition
      ].push(root);
      this.setState({ flag: false });
    }
  }

  render() {
    return (
      <div className="reset-btn-bottom" ref={this.addToMap.bind(this)}>
        {this.props.children}
      </div>
    );
  }
}
