import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Bus from './Bus-View';
import { BusContext } from '../../Contexts/BusContext';

class BusContainer extends Component {
	static contextType = BusContext;

	componentDidMount() {
		this._isMounted = true;
		window.scrollTo({
			top: 0
		});
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	handleAlerts = () => {
		this.props.history.push('/faults-and-alerts');
	};

	getFaultsList = (activeBusIssue, oldActiveBusIssue) => {
		const stopNowFaults = [];
		const serviceNowFaults = [];
		const serviceSoonFaults = [];
		const allPriorityFaults = [];
		const nextPlannedFaults = [];
		activeBusIssue.forEach((fault) => {
			fault.equipmentId = fault.busID;
			fault.vin = fault.vin || '';
			oldActiveBusIssue.forEach((oldIssue) => {
				if (oldIssue.equipmentId === fault.busID) {
					fault.vin = oldIssue.vin || '';
				}
			});
			switch (fault.severity) {
				case 'Stop Now':
					stopNowFaults.push(fault);
					break;

				case 'Service Now':
					serviceNowFaults.push(fault);
					break;

				case 'Service Soon':
					serviceSoonFaults.push(fault);
					break;

				case 'Information':
					nextPlannedFaults.push(fault);
					break;
			}
		});
		allPriorityFaults.push(stopNowFaults);
		allPriorityFaults.push(serviceNowFaults);
		allPriorityFaults.push(serviceSoonFaults);
		allPriorityFaults.push(nextPlannedFaults);
		return allPriorityFaults;
	};

	render() {
		return (
			<BusContext.Consumer>
				{({
					equipmentStatus,
					engineDetails,
					activeAlertData,
					totalIssueCount,
					customerNameData,
					averageKM
				}) => {
					let showSnackBar;
					let allPriorityFaults;

					if (activeAlertData) {
						showSnackBar = totalIssueCount === 0;
						allPriorityFaults = this.getFaultsList(activeAlertData, engineDetails);
					}
					return (
						<Bus
							activeAlertData={activeAlertData}
							inActiveIssues={[]}
							equipmentStatus={equipmentStatus || []}
							allPriorityFaults={allPriorityFaults || []}
							handleAlerts={this.handleAlerts}
							showSnackBar={showSnackBar}
							totalIssueCount={totalIssueCount}
							customerNameData={customerNameData}
							averageKM={averageKM}
						/>
					);
				}}
			</BusContext.Consumer>
		);
	}
}
export default withRouter(BusContainer);
