import ReactGA from 'react-ga';

export const initGA = (trackingID, userID) => {
  ReactGA.initialize(trackingID, {
    gaOptions: { userId: userID },
    titleCase: false
  });
};

export const pageView = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
};

export const logEvent = (eventData) => {
  ReactGA.event(eventData);
}
