import axios from 'axios';

let _token = null;
let _APItoken = null;
let _baseAPI = null;
let _baseExtAPI = null;
let _baseSimulationAPI = null;

function setToken(token) {
    _token = token;
}

function setConfig(baseAPI, apiToken, simulationAPI) {
    _baseAPI = baseAPI;
    _baseExtAPI = `${baseAPI}/ext`
    _APItoken = apiToken;
  _baseSimulationAPI = simulationAPI;
}

// API endpoints
const EQUIPMENT_STATUS_API_BASE = {
    url: () => `${_baseExtAPI}/dashboard/equipstatus?customerName=`
};

const METACX_EVENT_BASE = {
    url: () => `${_baseAPI}/metric`
};

const TOTAL_KM_PER_MILE = {
    url: () => `${_baseExtAPI}/dashboard/totalkwhpermile`
};

const BUS_PERFORMANCE_ANALYTICS = {
    url: () => `${_baseExtAPI}/analytics/bus`
};

const BUS_ISSUES_API_BASE = {
    url: () => `${_baseExtAPI}/issues`
};

const BUS_CALIBRATION_API_BASE = {
    url: () => `${_baseExtAPI}/calibrations`,
    approveUrl: () => `${_baseExtAPI}/calibrations`
};

const ACTIVE_ALERTS_BASE = {
    url: () => `${_baseExtAPI}/users/alerts`
};

const COMMENTS_BASE = {
    getAllUrl: () => `${_baseExtAPI}/comments/get-comments`,
    addUrl: () => `${_baseExtAPI}/comments`
};

const CUSTOMER_NAME_API_BASE = {
    url: () => `${_baseExtAPI}/dashboard`
};

function createAxios() {
    const axiosInstance = axios.create();
    axiosInstance.interceptors.request.use(
        request => {
            if (_token) {
                request.headers.Authorization = `Bearer ${_token}`;
            }
            if (_APItoken) {
                request.headers['x-api-key'] = _APItoken;
            }
            return request;
        },
        error => Promise.reject(error)
    );
    axiosInstance.interceptors.response.use(
        response => response,
        error => Promise.reject(error)
    );
    return axiosInstance;
}

const equipmentStatus = {
    get: (customerName = 'Coach USA Inc.') => {
        const axiosInstance = createAxios();
        return axiosInstance({
            method: 'GET',
            url: EQUIPMENT_STATUS_API_BASE.url() + customerName
        })
            .then(res => res.data.Items)
            .catch(err => err);
    }
};

const getCustomerName = {
    get: payload => {
        const axiosInstance = createAxios();
        return axiosInstance({
            method: 'POST',
            url: CUSTOMER_NAME_API_BASE.url(),
            data: payload
        })
            .then(res => res.data)
            .catch(err => err);
    }
};

const busIssues = {
    get: payload => {
        const axiosInstance = createAxios();
        return axiosInstance({
            method: 'POST',
            url: BUS_ISSUES_API_BASE.url(),
            data: payload
        })
            .then(res => res.data)
            .catch(err => err);
    }
};

const activeAlerts = {
    get: payload => {
        const axiosInstance = createAxios();
        return axiosInstance({
            method: 'POST',
            url: ACTIVE_ALERTS_BASE.url(),
            data: payload
        })
            .then(res => res.data)
            .catch(err => err);
    }
};

const busCalibration = {
    get: payload => {
        const axiosInstance = createAxios();
        return axiosInstance({
            method: 'GET',
            url: `${BUS_CALIBRATION_API_BASE.url()}?customerFK=${
                payload.customerFK
            }`
        });
    },
    approve: payload => {
        const axiosInstance = createAxios();
        return axiosInstance({
            method: 'POST',
            url: BUS_CALIBRATION_API_BASE.approveUrl(),
            data: payload
        });
    }
};

const manageAlerts = {
    get: payload => {
        const axiosInstance = createAxios();
        return axiosInstance({
            method: 'POST',
            url: `${_baseExtAPI}/preferences/get-preferences`,
            data: payload
        });
    },

    post: data => {
        const axiosInstance = createAxios();
        return axiosInstance({
            method: 'POST',
            url: `${_baseExtAPI}/preferences/alerts`,
            data
        });
    }
};

const acknowledgeFault = {
    post: payload => {
        const axiosInstance = createAxios();
        return axiosInstance({
            method: 'POST',
            url: `${_baseExtAPI}/acknowledge`,
            data: payload
        })
    }
};

const faultComments = {
    getAll: payload => {
        const axiosInstance = createAxios();
        return axiosInstance({
            method: 'POST',
            url: COMMENTS_BASE.getAllUrl(),
            data: payload
        })
            .then(res => res.data)
            .catch(err => err);
    },
    add: payload => {
        const axiosInstance = createAxios();
        return axiosInstance({
            method: 'POST',
            url: COMMENTS_BASE.addUrl(),
            data: payload
        })
            .then(res => res.data)
            .catch(err => err);
    }
};

const metaCx = {
  addCommment:  payload=> {
    const axiosInstance=createAxios();
    return axiosInstance({
      method: 'POST',
      url: METACX_EVENT_BASE.url(),
        data: payload
    })
    .catch(err => err);
    }
};




const totalKwhPerMile = {
    get: payload => {
        const axiosInstance = createAxios();
        return axiosInstance({
            method: 'GET',
            url: `${TOTAL_KM_PER_MILE.url()}?startDate=${
                payload.startDate
            }&endDate=${payload.endDate}&custAccountName=${
                payload.custName
            }&fleetLevel=${payload.fleetLevel}`
        })
            .then(res => res.data)
            .catch(err => err);
    }
};

const totalKwh = {
    getTotalDistance: payload => {
        const axiosInstance = createAxios();

        return axiosInstance({
            method: 'GET',
            url: `${_baseExtAPI}/dashboard/totaldistance?startDate=${payload.startDate}&endDate=${payload.endDate}&custAccountName=${payload.custName}`
        })
            .then(res => res.data)
            .catch(err => err);
    },
    getTotalConsuption: payload => {
        const axiosInstance = createAxios();

        return axiosInstance({
            method: 'GET',
            url: `${_baseExtAPI}/dashboard/totalkwhconsumption?startDate=${payload.startDate}&endDate=${payload.endDate}&custAccountName=${payload.custName}`
        })
            .then(res => res.data)
            .catch(err => err);
    }
};

const performance = {
    getBusPerformanceAnalytics: payload => {
        const axiosInstance = createAxios();
        return axiosInstance({
            method: 'POST',
            url: BUS_PERFORMANCE_ANALYTICS.url(),
            data: payload
        })
            .then(res => res.data)
            .catch(err => err);
    }
};

const simulation = {
  // Get a list of routes for a dropdown
  getRoutes: () => {
    const axiosInstance = createAxios();
    return axiosInstance({
      method: 'GET',
      url: `${_baseSimulationAPI}/route`
    }).then(
      (response) => response.data
    ).catch((error) => error);
  },

  // Get the results for a specific simulation.
  // Will return a 404 if the simulation is not complete
  getResults: (simulationId) => {
    const axiosInstance = createAxios();
    return axiosInstance({
      method: 'GET',
      url: `${_baseSimulationAPI}/job/${simulationId}/result`,
      validateStatus: (status) => status === 200 || 404
    }).then(
      (response) => response.data
    ).catch((error) => error);
  },

  // Get the simulation info, including original params and params sent to
  // MatLab model.
  getSimulation: (simulationId) => {
    const axiosInstance = createAxios();
    return axiosInstance({
      method: 'GET',
      url: `${_baseSimulationAPI}/job/${simulationId}`
    }).then(
      (response) => response.data
    ).catch((error) => error);
  },

  // Get a list of the simulations
  // API has ability to filter by a specific date, but not implemented here.
  getSimulations: () => {
    const axiosInstance = createAxios();
    return axiosInstance({
      method: 'GET',
      url: `${_baseSimulationAPI}/job`
    }).then(
      (response) => response.data
    ).catch((error) => error);
  },

  // Get a list of tires for a dropdown
  getTires: () => {
    const axiosInstance = createAxios();
    return axiosInstance({
      method: 'GET',
      url: `${_baseSimulationAPI}/tire`
    }).then(
      (response) => response.data
    ).catch((error) => error);
  },

  // Get a list of vehicles for a dropdown (probably only 1 vehicle)
  getVehicles: () => {
    const axiosInstance = createAxios();
    return axiosInstance({
      method: 'GET',
      url: `${_baseSimulationAPI}/vehicle`
    }).then(
      (response) => response.data
    ).catch((error) => error);
  },

  // crate a new simulation job
  postSimulation: (data) => {
    const axiosInstance = createAxios();
    return axiosInstance({
      data,
      method: 'POST',
      url: `${_baseSimulationAPI}/job`
    }).then(
      (response) => response.data
    ).catch((error) => error);
  }
};

const trimManagement = {
  getTrim: (esns) => {
    if (esns.length > 1) {
      return ([
        {
          customerEquipId: 'EV_TestVehicle_1',
          ssn: '123456',
          trim: {
            tireSize: 321,
            variableAccelAccel1: 77.77,
            variableAccelAccel2: 77.77,
            variableAccelSpeed1: 77.77,
            variableAccelSpeed2: 77.77,
            maxRoadSpeedTrim2: 77.77,
            globalRoadSpeedMaximum: 77.77,
            outerTargetVoltageValue: 77.77,
            lowSocPowerAdjustmentEnable: 9
          }
        }, {
          customerEquipId: 'EV_TestVehicle_2',
          ssn: '654321',
          trim: {
            tireSize: 123,
            variableAccelAccel1: 99.99,
            variableAccelAccel2: 99.99,
            variableAccelSpeed1: 99.99,
            variableAccelSpeed2: 99.99,
            maxRoadSpeedTrim2: 99.99,
            globalRoadSpeedMaximum: 99.99,
            outerTargetVoltageValue: 99.99,
            lowSocPowerAdjustmentEnable: 3
          }
        }
      ]);
    }
    return ([
      {
        customerEquipId: 'EV_TestVehicle_1',
        ssn: '123456',
        trim: {
          tireSize: 321,
          variableAccelAccel1: 77.77,
          variableAccelAccel2: 77.77,
          variableAccelSpeed1: 77.77,
          variableAccelSpeed2: 77.77,
          maxRoadSpeedTrim2: 77.77,
          globalRoadSpeedMaximum: 77.77,
          outerTargetVoltageValue: 77.77,
          lowSocPowerAdjustmentEnable: 9
        }
      }
    ]);
    /*
    const esnList = esns.join(',');
    const axiosInstance = createAxios();
    return axiosInstance({
      method: 'GET',
      url: `${_baseExtAPI}/vehicleTrim?vehicles=${esnList}`
    }).then(
      (response) => response.data
    ).catch((error) => error);
     */
  }
};

export default {
    equipmentStatus,
    busIssues,
    busCalibration,
    manageAlerts,
    setConfig,
    setToken,
    acknowledgeFault,
    faultComments,
    metaCx,
    activeAlerts,
    getCustomerName,
    totalKwhPerMile,
    totalKwh,
    performance,
    simulation,
    trimManagement
};
