import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';

import { useBus } from 'components/Contexts/BusContext';
import API from 'utils/api';
import { ConfirmationDialog } from './ConfirmationDialog/ConfirmationDialog';
import { SelectVehicles } from './SelectVehicles/SelectVehicles';
import { TrimManagementForm } from './TrimManagementForm/TrimManagementForm';
import { TrimProgress } from './TrimProgress/TrimProgress';
import styles from './TrimManagement.module.css';

export const TrimManagement = () => {
  const [selectedVehicleTrims, setSelectedVehicleTrims] = useState([]);
  const [trimProgress, setTrimProgress] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [waiting, setWaiting] = useState(true);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const { busStatus } = useBus();

  useEffect(() => {
    // retrieve trim progress and list of vehicles
    setTrimProgress([]);
    const ESNs = busStatus ? Object.getOwnPropertyNames(busStatus) : [];
    const v = ESNs.map((esn) => {
      const vehicle = busStatus[esn];
      return {
        customerEquipId: vehicle.customerEquipId,
        esn,
        equipVin: vehicle.equipVin
      };
    });
    setVehicles(v);
    setWaiting(false);
  }, [busStatus]);

  const handleSelectVehicles = (vehicleSSNs) => {
    // GET the current trim for the selected vehicles
    // just placeholder stuff for now...
    // const trims = vehicleSSNs.map((ssn) => busStatus[ssn]);
    const trims = API.trimManagement.getTrim(vehicleSSNs);
    setSelectedVehicleTrims(trims);
  };

  const handleFormCancel = () => {
    window.scrollTo({
      behavior: 'smooth',
      left: 0,
      top: 0
    });
    // add a slight delay so that the above scrolling starts before a re-render
    setTimeout(setSelectedVehicleTrims, 250, []);
  };

  const handleConfirmCancel = () => {
    setIsConfirmationOpen(false);
  };

  const handleSubmit = (form) => {
    // POST the formData
    setFormData(form);
    setIsConfirmationOpen(true);
  };

  return (
    <div className={styles.trimManagement}>
      <TrimProgress progress={trimProgress} />

      <SelectVehicles
        enabled={!selectedVehicleTrims.length}
        onVehicleSelect={handleSelectVehicles}
        vehicles={vehicles}
      />

      { !!selectedVehicleTrims.length &&
        <TrimManagementForm
          onCancel={handleFormCancel}
          onSubmit={handleSubmit}
          vehicleTrims={selectedVehicleTrims}
        />
      }
      <ConfirmationDialog form={formData} onCancel={handleConfirmCancel} isOpen={isConfirmationOpen} />
      { waiting && <CircularProgress/> }
    </div>
  );
};
