import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Simulator from './Simulator/Simulator.jsx';
import SavedScenarios from './SavedScenarios/SavedScenarios.jsx';

export const ScenarioSimulator = () => {
  const { tab = 'scenario' } = useParams();
  const history = useHistory();
  const [tabValue, setTabValue] = useState(tab);

  // When the user changes tab, update the history/URL.
  // The `useEffect` below will then change the component's state.
  const changeTab = ( e, newValue ) => {
    history.push(`/range-estimation/${newValue}`);
  };

  // This will watch the `tab` path param and change state accordingly. Without
  // this, the back/forward browser buttons won't trigger a state change and
  // won't change the tab.
  useEffect(() => {
    setTabValue(tab);
  }, [tab]);

  return (
    <>
      <AppBar position='static' className='tab-bar'>
        <Tabs value={tabValue} onChange={changeTab}>
          <Tab label='SCENARIO' className='tab' value="scenario"/>
          <Tab label='SAVED SCENARIOS' className='tab' value="saved"/>
        </Tabs>
      </AppBar>
      { tabValue === 'scenario' && <Simulator /> }
      { tabValue === 'saved' && <SavedScenarios /> }
    </>
  );
};
