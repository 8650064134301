import React, { useContext } from 'react';
import { CircularProgress } from '@material-ui/core';
import { BusContext } from '../../Contexts/BusContext';
import { Print } from './Print/Print';

export const Analytics = () => {
  const context = useContext(BusContext);
  return (
    <>
      {context.customerNameData ? (
        <Print custName={context.customerNameData.custName} />
      ) : (
        <div className='page-loader'>
          <CircularProgress />
        </div>
      )}
    </>
  );
};
