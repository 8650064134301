/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { BusContext } from 'components/Contexts/BusContext';
import UpdatesTable from './UpdatesTable.jsx';
import HappyBus from '../../../../assets/Icons/happy-bus.svg';
import BusIcon from '../../../../assets/Icons/icon_bus.svg';
import IconElectricity from '../../../../assets/Icons/icon_electricity.svg';
import './UpdatesAvailable.scss';
import api from '../../../../utils/api';
import { AuthContext } from '../../../Contexts/AuthContext';
import STATUSKeywordMap from '../../../../utils/STATUSKeywordMap';
import { ProgressBar } from './ProgressBar.jsx';

const STATUS = {
	WAITINGAPPROVAL: 'waitingApproval',
	APPROVED: 'approved',
	DOWNLOADING: 'downloading',
	DOWNLOADED: 'downloaded',
	INSTALLING: 'installing',
	UPTODATE: 'upToDate',
	DOWNLOADING_FAILED: 'downloadingFailed',
	INSTALLING_FAILED: 'installingFailed'
};

export class UpdatesAvailable extends Component {
	static contextType = AuthContext;

	state = {
		open: false,
		fleetData: [],
		isAutoApprove: false,
		loading: true
	};

	componentDidMount() {
		this.getBusCalibration();
	}

	getBusCalibration = () => {
		api.busCalibration
			.get({
				customerFK: this.context.customerList
			})
			.then((res) => {
				const { data } = res.data;
				data.forEach((item) => {
					item.calStatus = STATUSKeywordMap.get(item.calStatus.toLowerCase());
					if (item.calStatus === STATUS.WAITINGAPPROVAL) {
						item.isSelected = false;
					}
				});
				this.setState({ fleetData: data, loading: false });
			})
			.catch(() => {
				this.setState({ loading: false });
			});
	};

	handleSelectAllClick = (e) => {
		const { checked } = e.target;
		this.setState((prev) => {
			const newSelected = prev.fleetData;
			if (checked) {
				newSelected.forEach((item) => {
					if (item.calStatus === STATUS.WAITINGAPPROVAL) {
						item.isSelected = true;
					}
				});
			} else {
				newSelected.forEach((item) => {
					if (item.calStatus === STATUS.WAITINGAPPROVAL) {
						item.isSelected = false;
					}
				});
			}
			return { fleetData: newSelected };
		});
	};

	handleCheckboxClick = (event, bus) => {
		const selectedIndex = this.state.fleetData.indexOf(bus);
		const newSelected = this.state.fleetData;
		newSelected[selectedIndex].isSelected = !newSelected[selectedIndex].isSelected;
		this.setState({ fleetData: newSelected });
	};

	handleClickOpen = (bus) => {
		if (bus) {
			const selectedIndex = this.state.fleetData.indexOf(bus);
			const newSelected = this.state.fleetData;
			newSelected.forEach((e) => {
				e.isSelected = false;
			});
			newSelected[selectedIndex].isSelected = true;
			this.setState({
				open: true,
				fleetData: newSelected
			});
		} else {
			this.setState({
				open: true
			});
		}
	};

	handleClose = () => {
		const newSelected = this.state.fleetData;
		newSelected.forEach((item) => {
			if (item.isSelected) item.isSelected = false;
		});
		this.setState({ open: false, fleetData: newSelected });
	};

	handleApprove = () => {
		this.setState({ loading: true, open: false });
		const calStatusIds = [];
		this.state.fleetData.forEach((item) => {
			if (item.isSelected) {
				calStatusIds.push(item.calStatusId);
			}
		});
		api.busCalibration
			.approve({
				calStatus: 'APPROVED',
				calStatusIds
			})
			.then(() => {
				this.getBusCalibration();
			})
			.catch(() => {
				this.getBusCalibration();
			});
	};

	render() {
		let waitingApproval = 0;
		let inProgress = 0;
		let upToDate = 0;
		let failed = 0;

		this.state.fleetData.forEach((item) => {
			switch (item.calStatus) {
				case STATUS.WAITINGAPPROVAL:
					waitingApproval += 1;
					break;
				case STATUS.APPROVED:
					inProgress += 1;
					break;
				case STATUS.DOWNLOADING:
					inProgress += 1;
					break;
				case STATUS.DOWNLOADED:
					inProgress += 1;
					break;
				case STATUS.INSTALLING:
					inProgress += 1;
					break;
				case STATUS.UPTODATE:
					upToDate += 1;
					break;
				case STATUS.DOWNLOADING_FAILED:
					failed += 1;
					break;
				case STATUS.INSTALLING_FAILED:
					failed += 1;
					break;
				default:
					break;
			}
		});

		return (
			<BusContext.Consumer>
				{({ busStatus }) => (
					<div className="updates-content-container">
						{this.state.loading || !busStatus ? (
							<div className="page-loader">
								<CircularProgress />
							</div>
						) : (
							<Grid container spacing={3}>
								<Grid item sm={12} md={6}>
									{waitingApproval > 0 ? (
										<React.Fragment>
											{failed === 0 ? (
												<Accordion>
													<AccordionSummary
														expandIcon={<ExpandMoreIcon />}
														classes={{
															root: 'my-ExpansionPanelSummary'
														}}
													>
														<div className="current-release">
															<h3>New Software Updates Available</h3>
														</div>
													</AccordionSummary>
													<AccordionDetails
														classes={{
															root: 'my-ExpansionPanelDetails'
														}}
													>
														<div className="banner">
															Updating bus software is a simple 4 step
															process:
														</div>

														<div className="steps" id="accordionPopup">
															<p>By clicking <span>APPROVE</span>, you are agreeing to update the system controller with a new software.</p>
															<p>
																<span className="circleNumber">1</span>
																Click <span>APPROVE</span>
															</p>
															<p>
																<span className="circleNumber">2</span>
																Software will <span>DOWNLOAD</span> to system controller. Bus can be used normally during this step*
															</p>
															<p>
																<span className="circleNumber">3</span>
																<span>PLUG-IN</span> to charge bus to 100%
															</p>
															<p>
																<span className="circleNumber">4</span>
																Software <span>INSTALLATION</span> will occur automatically. This can take up to 5 minutes to complete**
															</p>
															<div className="note">
																*The download step can take up to 1 day, depending on cellular connectivity
																<br />
																**In case of an installation failure, the original calibration is restored automatically, and your bus will be fully operational
															</div>
														</div>
													</AccordionDetails>
												</Accordion>
											) : (
												<Paper className="failed">
													<div>
														<h2>Oops!</h2>
														<em>
															The software update could not be completed and
															the equipment is using the previous software
															version. Contact Cummins Customer support at
															<a href="mailto:AcceleraDigital.Support@cummins.com"> AcceleraDigital.Support@cummins.com </a>
															for assistance.
														</em>
													</div>
												</Paper>
											)}
											<UpdatesTable
												clickOpen={this.handleClickOpen}
												fleetData={this.state.fleetData.filter(
													(item) => item.calStatus === STATUS.WAITINGAPPROVAL
												)}
												handleSelectAllClick={this.handleSelectAllClick}
												handleCheckboxClick={this.handleCheckboxClick}
												STATUS={STATUS}
											/>
										</React.Fragment>
									) : failed === 0 ? (
										<Paper className="updates-info">
											<div className="title">
												{inProgress > 0 ? 'Please Wait ...' : 'Congratulations!'}
											</div>

											<div className={inProgress > 0 ? 'plsWait' : 'congras'}>
												{inProgress > 0 ? (
													<div>
														<i className="far fa-clock fa-lg" />
														Software Updates are in Progress
													</div>
												) : (
													<div>
														<i className="fa fa-check-circle fa-lg" />
														Your Fleet is Up-to-Date
													</div>
												)}
											</div>

											<div className="busIcons">
												<img
													src={inProgress > 0 ? BusIcon : HappyBus}
													alt="bus icon"
												/>
												<img
													src={inProgress > 0 ? BusIcon : HappyBus}
													alt="bus icon"
												/>
												<img
													src={inProgress > 0 ? BusIcon : HappyBus}
													alt="bus icon"
												/>
											</div>
										</Paper>
									) : (
										<Paper className="failed">
											<div>
												<h2>Oops!</h2>
												<em>
													The software update could not be completed and
													the equipment is using the previous software
													version. Contact Cummins Customer support at
													<a href="mailto:AcceleraDigital.Support@cummins.com"> AcceleraDigital.Support@cummins.com </a>
													for assistance.
												</em>
											</div>
										</Paper>
									)}
								</Grid>

								<Grid item sm={12} md={6}>
									{(inProgress > 0 || failed > 0) && (
										<Paper className="update-in-progress">
											<p className="table-title">Software Update in Progress</p>
											<Table>
												<TableHead>
													<TableRow>
														<TableCell>Bus</TableCell>
														<TableCell>Updating Version</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{this.state.fleetData.map((item) => {
														if (
															item.calStatus !== STATUS.WAITINGAPPROVAL
															&&
															item.calStatus !== STATUS.UPTODATE
														) {
															return (
																<React.Fragment key={item.engineSerialNum}>
																	<TableRow>
																		<TableCell>
																			<div>
																				<img
																					src={IconElectricity}
																					className="icon-electricity"
																					alt="electricity icon"
																				/>
																				<span className="update-list-bus-name">
																					{busStatus && busStatus[item.engineSerialNum]
																						? busStatus[item.engineSerialNum].customerEquipId
																						: item.engineSerialNum
																					}
																				</span>
																			</div>
																		</TableCell>

																		<TableCell align="left">
																			<div>
																				<strong>
																					{
																						item.pendingCalRevision
																					}
																				</strong>
																			</div>
																		</TableCell>
																	</TableRow>
																	<TableRow>
																		<TableCell colSpan={3}>
																			<ProgressBar
																				calStatus={item.calStatus}
																				STATUS={STATUS}
																			/>
																		</TableCell>
																	</TableRow>
																</React.Fragment>
															);
														}
													})}
												</TableBody>
											</Table>
										</Paper>
									)}

									{upToDate > 0 && (
										<Paper className="up-to-date">
											<p className="table-title">Up-to-Date</p>
											<Table>
												<TableHead>
													<TableRow>
														<TableCell>Bus</TableCell>
														<TableCell>Up-to-Date Version</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{this.state.fleetData.map((item) => {
														if (item.calStatus === STATUS.UPTODATE) {
															return (
																<TableRow key={item.busID}>
																	<TableCell>
																		<div>
																			<img
																				src={IconElectricity}
																				className="icon-electricity"
																				alt="electricity icon"
																			/>
																			<span className="bus-link">
																				{busStatus && busStatus[item.engineSerialNum]
																					? busStatus[item.engineSerialNum].customerEquipId
																					: item.engineSerialNum
																				}
																			</span>
																		</div>
																	</TableCell>

																	<TableCell align="left">
																		<div>
																			<strong>
																				{item.currentCalRevision}
																			</strong>
																		</div>
																	</TableCell>
																</TableRow>
															);
														}
													})}
												</TableBody>
											</Table>
										</Paper>
									)}
								</Grid>
							</Grid>
						)}
						<Dialog onClose={this.handleClose} open={this.state.open} maxWidth="xl">
							<div className="approve-dialog">
								<div className="select-bar">
									<h3>Authorization Required</h3>
									<div className="approve-dialog-buttons">
										<Button variant="contained" onClick={this.handleClose}>
											Cancel
										</Button>
										<Button
											variant="contained"
											color="primary"
											onClick={this.handleApprove}
										>
											Approve
										</Button>
									</div>
								</div>
								<div className="banner">
									Updating bus software is a simple 4 step process:
								</div>

								<div className="steps" id="accordionPopup">
									<p>By clicking <span>APPROVE</span>, you are agreeing to update the system controller with a new software.</p>
									<p>
										<span className="circleNumber">1</span>
										Click <span>APPROVE</span>
									</p>
									<p>
										<span className="circleNumber">2</span>
										Software will <span>DOWNLOAD</span> to system controller. Bus can be used normally during this step*
									</p>
									<p>
										<span className="circleNumber">3</span>
										<span>PLUG-IN</span> to charge bus to 100%
									</p>
									<p>
										<span className="circleNumber">4</span>
										Software <span>INSTALLATION</span> will occur automatically. This can take up to 5 minutes to complete**
									</p>
									<div className="note">
										*The download step can take up to 1 day, depending on cellular connectivity
										<br />
										**In case of an installation failure, the original calibration is restored automatically, and your bus will be fully operational
									</div>
								</div>
							</div>
						</Dialog>
					</div>
				)}
			</BusContext.Consumer>
		);
	}
}
