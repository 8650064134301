import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Changelog } from 'components/Containers/Changelog/Changelog.jsx';
import Bus from './Containers/Bus/Bus-Container';
import Charger from './Containers/Charger';
import { Analytics } from './Containers/Analytics/Analytics';
import { Updates } from './Containers/Updates/Updates.jsx';
import ManageAlerts from './Containers/ManageAlerts';
import { useFeatureFlags } from './Contexts/FeatureFlagContext';
import useBusPath from '../hooks/useBusPath';
import {AlertsAndIssues} from './Containers/AlertsAndIssues/AlertsAndIssues';
import { ScenarioSimulator } from './Containers/ScenarioSimulator/ScenarioSimulator';
import {FaultDetail} from './Containers/FaultDetail/FaultDetail';
import { BusDetail } from './Containers/BusDetail/BusDetail';


export const Routes = () => {
  const flags = useFeatureFlags();
  const busPath = useBusPath()
  return (
    <Switch>
      <Route exact path="/"><Redirect to={busPath} /></Route>
      {// Redirect due to chargeMangement feature flag loading after initial page load
        flags.chargeManagement && <Route exact path="/buses"><Redirect to={busPath}/></Route>
      }
      <Route exact path={busPath} component={Bus} />
      {flags.chargeManagement && (
        <Route exact path='/assets/chargers' component={Charger}/>
      )}
      <Route path={`${busPath}/:esn/:tab`} component={BusDetail}/>
      <Route exact path="/faults-and-alerts" component={AlertsAndIssues} />
      <Route exact path="/faults-and-alerts/:tab" component={AlertsAndIssues} />
      <Route exact path="/faults-and-alerts/:type/:faultID" component={FaultDetail}/>
      <Route path="/analytics" component={Analytics} />
      <Route path="/software-updates/:tab" component={Updates} />
      <Route path="/manage-alerts" component={ManageAlerts} />
      { flags.scenarioSimulator && <Route exact path="/range-estimation/:tab/:simulationId" component={ScenarioSimulator} /> }
      { flags.scenarioSimulator && <Route exact path="/range-estimation/:tab" component={ScenarioSimulator} /> }
      { flags.scenarioSimulator && <Route exact path="/range-estimation" component={ScenarioSimulator} /> }
      { flags.changelog && <Route path="/changelog" component={Changelog} /> }
    </Switch>
  )
};
