import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Paper } from '@material-ui/core';

import { SelectableVehicle } from './SelectableVehicle/SelectableVehicle';

import './SelectVehicles.module.css';

export const SelectVehicles = ({ enabled, onVehicleSelect, vehicles }) => {
  const [selectedVehicles, setSelectedVehicles] = useState(new Set(null));

  /**
   * Call the submit handler, passing the Set of `selectedVehicles` as an Array
   * @param e The `submit` event
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    onVehicleSelect(Array.from(selectedVehicles));
  };

  /**
   * Update the Set of selected ESNs stored in the `selectedVehicles` state obj
   * @param esn The ESN to add/remove
   * @param checked Whether the ESN was selected (true) or unselected (false)
   */
  const handleSelection = (esn, checked) => {
    setSelectedVehicles(
      (prevChecked) => {
        if (checked) {
          prevChecked.add(esn);
        } else {
          prevChecked.delete(esn);
        }
        return new Set(prevChecked);
      }
    );
  };

  return (
    <Paper className="selectVehicles">
      <div className="stepHeader">
        <div className={enabled ? 'step current' : 'step'}>1</div>
        <div className="stepInstruction">Select which vehicle you would like to manage:</div>
      </div>
      <form onSubmit={handleSubmit} className="vehicleForm">
        <div className="vehicleList">
          { vehicles.map((v) => (
              <SelectableVehicle
                enabled={enabled}
                isSelected={selectedVehicles.has(v.esn)}
                key={`select_${v.esn}`}
                onSelect={handleSelection}
                vehicle={v}
              />
            ) )}
        </div>
        <div className="buttons">
          <Button
            color="primary"
            disabled={!enabled || !selectedVehicles.size}
            onClick={handleSubmit}
            variant="contained"
          >
            Next
          </Button>
        </div>
      </form>
    </Paper>
  );
};

SelectVehicles.propTypes = {
  enabled: PropTypes.bool.isRequired,
  onVehicleSelect: PropTypes.func.isRequired,
  vehicles: PropTypes.array.isRequired
};
