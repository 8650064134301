import React from 'react';

import DropDownButtonView from './DropDownButton-View';

export default function DropDownButton(props) {
  const [open, setOpen] = React.useState(false);
  const {buttonName} = props;
  const {avatar} = props;

  const handleClick = () => {
    setOpen(prev => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <DropDownButtonView
      handleClickAway={handleClickAway}
      handleClick={handleClick}
      buttonName={buttonName}
      avatar={avatar}
      open={open}
    />
  );
}
