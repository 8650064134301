import React from 'react';
import { useBus } from 'components/Contexts/BusContext';
import { logEvent } from 'utils/analyticsTracking';

export const SimulatorAddRoute = () => {
  const customer = useBus();
  let customerName = '';
  if (customer?.customerNameData?.custName) {
    customerName = ` for ${customer.customerNameData.custName}`;
  }
  const emailAddress = 'AcceleraDigital.Support@cummins.com';
  const mailUrl = `mailto:${emailAddress}?subject=Route Request${customerName}&body=Route Name:%0D%0ABus ID:%0D%0ARun Dates:%0D%0A`;

  const trackEmailClick = () => {
    logEvent({
      action: 'click',
      category: 'Range Estimation',
      label: 'email link'
    })
  }
  return (
    <div>
      <h3>Create New Route</h3>
      <p>Follow the steps outlined below to add a new route.</p>
      <p>
        <strong>Step 1: Collect Data</strong><br/>
        Please run the bus on the desired route for at least two days. If the bus is not in mission, stop at regular stops as per usual ridership. We request that you run the route at different times such as morning and peak hours, and late night at off-peak hours.
      </p>
      <p>
        <strong>Step 2: Contact Cummins</strong><br/>
        Once you've completed Step 1, please email <a href={mailUrl} onClick={trackEmailClick}>{emailAddress}</a> with the name of the route, the bus ID, and the dates data was collected.
      </p>
      <p>
        <strong>Step 3: Route Ready for Use</strong><br/>
        Cummins will process the data and get back to you via email once range estimation is available to use for your new route.
      </p>
      <p>
        Thank you for your patience.
      </p>
    </div>
  );
};
