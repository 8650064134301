import React from 'react';
import Card from '@material-ui/core/Card';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';

import './Charger.css';

const titleText = `We're sorry that your charger is not yet listed in our system`;
const helpTitleText = `Let's work together to get your new charger registered`;
const helpFirstParagraph = `
  Your charger manufacturer and/or model is not present in our system.
  Fill in as much information as you can to help us register your charger.
`;
const helpSecondParagraph = `
  This registration request could take a few weeks to process.
  You will be notified via email once it is complete.
`;

const ManualRegistrationForm = props => {

  const {
    state,
    onFieldChange,
  } = props;

  const {
    name,
    manufacturer,
    model,
    description
  } = state;


  return (
    <form
      className='charger-info-form'
      id="scroll-dialog-description"
    >
      <Card
        className='charger-info-form_card'
        variant='outlined'
      >
        <h3 className='charger-info-form_title'>Tell us about your charger</h3>
        <div className='charger-info-form_group'>
          <InputLabel
            className='charger-info-form_required-label'
            htmlFor='charger_form_name'
          >
            Charger Name
          </InputLabel>
          <TextField
            fullWidth={true}
            id='charger_form_name'
            name='name'
            placeholder='Name you use to reference this charger'
            variant='outlined'
            value={name.value}
            error={name.error !== ''}
            onChange={onFieldChange}
          />
          {name.error !== '' && <FormHelperText error>This field is required.</FormHelperText>}
        </div>
        <div className='charger-info-form_group'>
          <InputLabel
            className='charger-info-form_required-label'
            htmlFor='charger_form_manufacturer'
          >
            Manufacturer Name
          </InputLabel>
          <TextField
            fullWidth={true}
            id='charger_form_manufacturer'
            name='manufacturer'
            variant='outlined'
            value={manufacturer.value}
            error={manufacturer.error !== ''}
            onChange={onFieldChange}
          />
          {manufacturer.error !== '' && <FormHelperText error>This field is required.</FormHelperText>}
        </div>
        <div className='charger-info-form_group'>
          <InputLabel
            className='charger-info-form_required-label'
            htmlFor='charger_form_model'
          >
            Model
          </InputLabel>
          <TextField
            fullWidth={true}
            id='charger_form_model'
            name='model'
            variant='outlined'
            value={model.value}
            error={model.error !== ''}
            onChange={onFieldChange}
          />
          {model.error !== '' && <FormHelperText error>This field is required.</FormHelperText>}
        </div>
        <div className='charger-info-form_group'>
          <InputLabel
            className='charger-info-form_label'
            htmlFor='charger_form_description'
          >
            Charger Description
          </InputLabel>
          <TextField
            fullWidth={true}
            id='charger_form_description'
            name='description'
            placeholder='Name you use to reference this charger'
            variant='outlined'
            multiline={true}
            value={description.value}
            onChange={onFieldChange}
          />
        </div>
      </Card>
      <div className='charger-info-form_manual-help'>
        <h3 className='charger-info-form_manual-help_title'>{titleText}</h3>
        <Card
          className='charger-info-form_manual-help_card'
          variant='outlined'
        >
          <h4 className='charger-info-form_manual-help_card-title'>{helpTitleText}</h4>
          <p className='charger-info-form_manual-help_instructions'>
            {helpFirstParagraph}
            <br/>
            <br/>
            {helpSecondParagraph}
          </p>
        </Card>
      </div>
    </form>
  );
};

export default ManualRegistrationForm;