import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';

import './ConfirmationDialog.module.css';

export const ConfirmationDialog = ({ form, isOpen, onCancel }) => (
    <Dialog open={isOpen}>
      <DialogTitle>
        Future State: Submit the following values for trim update...
      </DialogTitle>
      <DialogContent>
        { Object.entries(form).map((entry) => (
          <div key={entry[0]} className="keyVal">
            <div className="keyVal_key">{ entry[0] }</div>
            <div className="keyVal_val">{ entry[1] }</div>
          </div>
        ))
        }
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={onCancel}
          variant="contained"
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
);

ConfirmationDialog.propTypes = {
  form: PropTypes.object,
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func.isRequired
};
