import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import ChargerForm from './ChargerForm';
import RegistrationSuccess from './RegistrationSuccess';
import useChargerFormState from './useChargerFormState';
import './Charger.css';
import ManualRegistrationForm from './ManualRegistrationForm';

const getEmptyRequiredFields = state => {

  const required = [
    'name', 'manufacturer', 'model'
  ];

  const invalidFields = required.filter(field => state[field].value.trim() === '');

  return {
    fields: invalidFields
  };
};

const ChargerModalContent = props => {

  const {
    onClose
  } = props;

  const [
    showSuccess,
    setShowSuccess
  ] = useState(false);

  const [
    showManual,
    setShowManual
  ] = useState(false);

  const {
    state,
    dispatch
  } = useChargerFormState();

  const handleSubmit = () => {

    const { fields } = getEmptyRequiredFields(state);
    const hasError = fields.length > 0;

    if (hasError) {
      dispatch({
        type: 'add field error',
        data: { fields }
      });
    }
    else if(!showManual) {
      setShowSuccess(true);
    }
    else {
      onClose();
    }
  };

  const handleShowManualRegistration = () => {
    dispatch({
      type: 'clear required field errors'
    });
    setShowManual(true);
  };

  const handleManualFieldChange = ({target}) => dispatch({
    type: 'update manual registration field',
    data: {
      field: target.name,
      value: target.value
    }
  });

  const handleFieldChange = ({ target }) => dispatch({
    type: 'update field',
    data: {
      field: target.name,
      value: target.value
    }
  });

  const handlePortChange = (portNumber, value) => dispatch({
    type: 'update field',
    data: {
      field: 'port',
      value,
      portNumber
    }
  });

  return (
    <>
      <div className='charger-info-form_header'>
        <DialogTitle id="scroll-dialog-title">
          Add Charger
        </DialogTitle>
        <IconButton
          className='charger-info-form_close-btn'
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </div>
      {!showSuccess ? (
        <>
          <DialogContent key='1' dividers={true}>
            {!showManual ? (
              <ChargerForm
                state={state}
                onShowManualRegistration={handleShowManualRegistration}
                onFieldChange={handleFieldChange}
                onPortChange={handlePortChange}
              />
            ) : (
              <ManualRegistrationForm
                state={state}
                onFieldChange={handleManualFieldChange} />
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={onClose}
              className='charger-info-form_cancel-btn'
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              className='charger-info-form_register-btn'
              color="primary"
              variant='contained'
            >
              {showManual ? 'Notify Cummins' : 'Register'}
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogContent
            key='2'
            className='charger-info-form_register-success-container'
            dividers={true}
          >
            <RegistrationSuccess/>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={onClose}
              className='charger-info-form_footer-close-btn'
              color="primary"
              variant='contained'
            >
              Close
            </Button>
          </DialogActions>
        </>
      )}
    </>
  );
};

const Charger = () => {

  const [
    showChargerModal,
    setShowChargerModal
  ] = useState(false);

  const handleClose = () => setShowChargerModal(false);

  return (
    <Grid container spacing={2} className='charger-grid'>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
      >
        <Paper>
          <div className='charger-container'>
            <Button
              className='add-charger-btn'
              color='primary'
              variant='contained'
              onClick={() => setShowChargerModal(true)}
            >
              <AddIcon />
              &nbsp; New Charger
            </Button>
            <Dialog
              open={showChargerModal}
              classes={{
                scrollPaper: 'charger-modal-scroll-paper',
                paper: 'charger-modal-paper'
              }}
              maxWidth='md'
              onClose={handleClose}
            >
              <ChargerModalContent onClose={handleClose}/>
            </Dialog>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Charger;