import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { Button } from '@material-ui/core';

const AlertPreferences = props => (
  <div className='alert-preferences-container'>
    <div className='alert-preferences-types'>
      <div className='alert-preferences-header'>Alert Type and Preferences for PrevenTech EV Alerts Page</div>
      <div className='alert-preferences-content'>
        <div>
          <Checkbox
            checked={props.immediate}
            className='immediate-check-box'
            onChange={() => props.onChange({ immediate: !props.immediate })}
          />
          <span className='email-alert-text'>Immediate</span>
        </div>
        <div>
          <Checkbox
            checked={props.serviceWithin3Days}
            className='service-within-3-days-check-box'
            onChange={() =>
              props.onChange({ serviceWithin3Days: !props.serviceWithin3Days })
            }
          />
          <span className='email-alert-text'>Service Within 3 Days</span>
        </div>
        <Checkbox
          checked={props.serviceWithin7Days}
          className='service-within-7-days-check-box'
          onChange={() =>
            props.onChange({ serviceWithin7Days: !props.serviceWithin7Days })
          }
        />
        <span className='email-alert-text'>Service Within 7 Days</span>
        <div>
          <Checkbox
            checked={props.nextPlanned}
            className='pm-check-box'
            onChange={() => props.onChange({ nextPlanned: !props.nextPlanned })}
          />
          <span className='email-alert-text'>Next Planned Maintenance</span>
        </div>
      </div>
    </div>
    <div className='alert-preferences-buttons'>
      <Button className='alert-preferences-cancel'>Cancel</Button>
      <Button
        className={`alert-preferences-submit ${
          props.isUpdateButtonDisabled ? 'disabled' : ''
        }`}
        onClick={props.updateSeverityInfo}
      >
        Update
      </Button>
    </div>
  </div>
);

export default AlertPreferences;
