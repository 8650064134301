import React from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';

import './Charger.css';

const successTitle = `Your charger is now registered in our system`;
const successCardTitle = `Let's work together to get your new charger connected`;

const instructionText = `
  Enter the below URL on your charger
  and make sure your charger is connected to the internet.
  This will allow us to connect to it.
`;

const helpText = `
  It may take a few hours or days to establish the connection
  depending on your charger model and manufacturer.
  You can monitor the status on "Assets -> Chargers."
`;

const RegistrationSuccess = props => {

  const {
    chargerLink = 'http://cmi.io/RkJ429GL3478'
  } = props;

  const handleCopy = () => {
    navigator.clipboard.writeText(chargerLink);
  };

  return (
    <div className='charger-register-success'>
      <h3 className='charger-register-success_title'>{successTitle}</h3>
      <Card className='charger-register-success_card'>
        <h4 className='charger-register-success_card-title'>{successCardTitle}</h4>
        <p className='charger-register-success_instruction-text'>{instructionText}</p>
        <div className='charger-register-success_copy-group'>
          <Card
            className='charger-register-success_copy-text'
            variant='outlined'>
            {chargerLink}
          </Card>
          <Button
            className='charger-register-success_copy-btn'
            color='primary'
            variant='contained'
            onClick={handleCopy}
          >
            Copy
          </Button>
        </div>
        <p className='charger-register-success_help-text'>{helpText}</p>
      </Card>
    </div>
  );
};

export default RegistrationSuccess;
