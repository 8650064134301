import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { formatDistanceToNow, isBefore, parseISO } from 'date-fns';
import { SimulatorAddRoute } from '../SimulatorAddRoute/SimulatorAddRoute.jsx';
import './SimulatorStatus.module.css';

// Check if the simulation's estFinishDate is in the future. False if no simulation.
const isFinishInFuture = (simulation) => {
  if (!simulation) {
    return false;
  }
  const estimatedCompletion = parseISO(simulation.estFinishDate);
  const now = new Date();
  return isBefore(now, estimatedCompletion);
};

// For a "new" simulation, display simple instructions.
// For a saved simulation, that is still calculating, display the estimated time
// remaining until the calculations are complete. Once the estimated finish time
// arrives, it requests the parent to check for results.
export const SimulatorStatus = ({ onRequestResults, simulation }) => {
  const [isWaiting, setIsWaiting] = useState(isFinishInFuture(simulation));
  const [intervalId, setIntervalId] = useState(null);
  const [estimate, setEstimate] = useState(null);

  useEffect(() => {
    let active = true;
    if (simulation) {
      const estimatedCompletion = parseISO(simulation.estFinishDate);
      setEstimate(formatDistanceToNow(estimatedCompletion));

      // Re-calculate time to finish every 60s
      const timeout = setInterval(() => {
        if (active) {
          if (isFinishInFuture(simulation)) {
            setIsWaiting(true);
            // update state so time remaining will change
            setEstimate(formatDistanceToNow(estimatedCompletion));
          } else {
            setIsWaiting(false);
            // have the parent check for results
            onRequestResults();
          }
        }
      }, 60000);
      setIntervalId(timeout);
    }

    return () => {
      active = false;
      if (intervalId) {
        // make sure the setInterval callback no longer gets called, and clear
        // the id from state.
        clearInterval(intervalId);
        setIntervalId(null);
      }
    }
  }, [simulation]);

  if (simulation) {
    return (
      <div className="status-container">
        { (simulation.status === 'pending' || simulation.status === 'running') &&
          <>
            <header className="simulationHeader">Calculating...</header>
            <div className="pending-simulation">
              <p>Simulation "{simulation.inputs.scenarioName}" has been submitted.</p>
              <CircularProgress className="spaced-spinner"/>
              { isWaiting
                ? <p>{ `Calculations should be complete in ${estimate}.` }</p>
                : <p>Simulation is still calculating.</p>
              }
            </div>
          </>
        }
        { simulation.status === 'failed' || simulation.status === 'timedout' &&
          <div>Simulation failed to complete.</div>
        }
      </div>
    );
  }

  return (
    <div className="status-container">
      <SimulatorAddRoute/>
    </div>
  );
};

SimulatorStatus.propTypes = {
  onRequestResults: PropTypes.func.isRequired,
  simulation: PropTypes.object
};
