import React from 'react';
import './UpdatesAvailable.scss';

export const ProgressBar = ({ calStatus, STATUS }) => (
    <div className="failed-notice">
        <div className="step">
            <span className="circle green">
                <i className="fas fa-check" />
            </span>
            <span>Approved</span>
        </div>

        <svg width="100%" height="24">
            <line x1="0" y1="12" x2="500" y2="12" stroke="#bdbdbd" strokeWidth="1" />
        </svg>

        <div className="step">
            <span
                className={
                    calStatus === STATUS.DOWNLOADING ? (
                        'circle green'
                    ) : calStatus === STATUS.DOWNLOADING_FAILED ? (
                        'circle red'
                    ) : calStatus === STATUS.INSTALLING ||
                    calStatus === STATUS.INSTALLING_FAILED ||   calStatus === STATUS.DOWNLOADED ? (
                        'circle green'
                    ) : (
                        'circle'
                    )
                }
            >
                {calStatus === STATUS.DOWNLOADING ? (
                    <i className="fas fa-check" />
                ) : calStatus === STATUS.DOWNLOADING_FAILED ? (
                    <i className="fas fa-exclamation" />
                ) : calStatus === STATUS.INSTALLING || calStatus === STATUS.INSTALLING_FAILED ||  calStatus === STATUS.DOWNLOADED ? (
                    <i className="fas fa-check" />
                ) : (
                    2
                )}
            </span>
            <span>Downloading</span>
        </div>

        <svg width="100%" height="24">
            <line x1="0" y1="12" x2="500" y2="12" stroke="#bdbdbd" strokeWidth="1" />
        </svg>

        <div className="step">
            <span
                className={
                    calStatus === STATUS.DOWNLOADED ? (
                        'circle green'
                    ) : calStatus === STATUS.INSTALLING ||
                    calStatus === STATUS.INSTALLING_FAILED ? (
                        'circle green'
                    ) : (
                        'circle'
                    )
                }
            >
                {calStatus === STATUS.DOWNLOADED ? (
                    <i className="fas fa-check" />
                ) : calStatus === STATUS.INSTALLING || calStatus === STATUS.INSTALLING_FAILED ? (
                    <i className="fas fa-check" />
                ) : (
                    3
                )}
            </span>
            <span>Downloaded</span>
        </div>

        <svg width="100%" height="24">
            <line x1="0" y1="12" x2="500" y2="12" stroke="#bdbdbd" strokeWidth="1" />
        </svg>

        <div className="step">
            <span
                className={
                    calStatus === STATUS.INSTALLING ? (
                        'circle green'
                    ) : calStatus === STATUS.INSTALLING_FAILED ? (
                        'circle red'
                    ) : (
                        'circle'
                    )
                }
            >
                {calStatus === STATUS.INSTALLING ? (
                    <i className="fas fa-check" />
                ) : calStatus === STATUS.INSTALLING_FAILED ? (
                    <i className="fas fa-exclamation" />
                ) : (
                    4
                )}
            </span>
            <span>Installing</span>
        </div>
    </div>
);
