import formatDistanceToNow from 'date-fns/formatDistanceToNow';

export const diffTime = date => {
    try {
        const currentDate = new Date(date);
        const utcDate = new Date(
            Date.UTC(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                currentDate.getDate(),
                currentDate.getHours(),
                currentDate.getMinutes(),
                currentDate.getSeconds()
            )
        );
        return `${formatDistanceToNow(new Date(utcDate.toUTCString()), {
            includeSeconds: true,
            addSuffix: true
        })}`;
    } catch (error) {
        return 'N/A';
    }
};
