import React from 'react';

export const CustomizedLabel = (props) => {
	const { x, y, value, max } = props;
	const stroke = '#000000';
	const roundedValue = Math.round(value * 100) / 100;
	if (parseFloat(value) === parseFloat(max)) {
		return (
			<text
				id="highestValue"
				x={x}
				y={y - 5}
				dy={-4}
				fill={stroke}
				fontSize={12}
				textAnchor="middle"
			>
				<tspan className="chartLineTitle" x={x} dy="-2em">
					Highest
				</tspan>
				<tspan className="chartLineValue" x={x} dy="1.3em">
					{roundedValue} kWh/mi
				</tspan>
			</text>
		);
	}
	return (
		<text x={x} y={y + 5} dy={-4} fill={stroke} fontSize={12} textAnchor="middle">
			<tspan className="chartLineTitle" x={x} dy="-2.8em">
				Lowest
			</tspan>
			<tspan className="chartLineValue" x={x} dy="1.3em">
				{roundedValue} kWh/mi
			</tspan>
		</text>
	);
};